import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'

import { AlertMessage } from '../../../plugins/alert-message'
import { createTask } from '../../../services/create-task'
import { downloadLink } from '../../../plugins/download-link'
import { reloadPage } from '../../../plugins/reload-page'
import { Modal } from '../../../plugins/modal'
import { campaignName } from '../../../plugins/campaign-name'
import { serverTime } from '../../../plugins/server-time'
import { reportCompletedIn } from '../../../plugins/report-completed-time'
import { statusReport } from '../../../plugins/status-report'

import './campaign-reports.css'

class CampaignReportsComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      message: null,
      errorMessage: null,
      campaignName: null,
      loading: true,
      reportsIsTrue: false,
      data: null,
      navigation: null,
      modal: false,
      modalText: {
        1: 'Создание отчета',
        2: 'Подтверждение запуска',
        3: 'Отмена',
        4: 'Подтвердить',
      },
    }
  }

  componentDidMount() {
    this.signal = axios.CancelToken.source()
    this.signal2 = null
    this.start = true
    this.start2 = true
    document.addEventListener('keydown', this.escFunction, false)

    this.getCampaignTasks()
    this.updatePage()
  }

  componentWillUnmount() {
    this.signal.cancel()

    if (this.signal2) {
      this.signal2.cancel()
    }

    this.start = false
    this.start2 = false
    clearInterval(this.timerId)
    document.removeEventListener('keydown', this.escFunction, false)
  }

  escFunction = (event) => {
    if (event?.keyCode === 27) {
      if (this.start) {
        this.setState({
          modal: false,
        })
      }
    }
  }

  updatePage = () => {
    this.timerId = setInterval(() => {
      if (this.start2) {
        this.getCampaignTasks(this?.state?.navigation?.current ? this?.state?.navigation?.current : 1)
      }
    }, 2000)
  }

  onDismiss = () => {
    this.setState({
      errorMessage: null,
    })
  }

  getCampaignTasks = (page = 1) => {
    if (!page) return

    if (this.signal2) {
      this.signal2.cancel()
    }

    this.signal2 = axios.CancelToken.source()

    const url = `${this?.props?.baseUrl}/api/campaign/${this?.props?.match?.params?.id}/tasks?page=${page}`
    axios
      .get(url, {
        cancelToken: this.signal2.token,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        this.start2 = true

        if (this.start && res?.status === 200 && res?.data?.results?.length === 0) {
          this.setState({
            loading: false,
            reportsIsTrue: false,
            message: null,
            errorMessage: null,
            campaignName: campaignName(res?.data?.campaign),
          })
          return
        }

        if (this.start && res?.status === 200 && res?.data?.results?.length > 0) {
          this.setState({
            loading: false,
            reportsIsTrue: true,
            data: res?.data?.results,
            message: null,
            campaignName: campaignName(res?.data?.campaign),
            navigation: {
              maxPage: Math.ceil(res?.data?.count / 10),
              previous: res?.data?.previous ? true : false,
              current: page,
              next: res?.data?.next ? true : false,
            },
          })
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401 && this.start) {
          reloadPage(e)
          return
        }

        if (e?.response?.status === 404 && this.start) {
          this.setState({
            message: (
              <>
                Кампания не существует, перейти на <Link to="/">главную.</Link>
              </>
            ),
            loading: false,
          })
          return
        }

        if (e?.__proto__?.__CANCEL__) {
          return
        }

        if (this.start) {
          this.start2 = true

          this.setState({
            message: <>Ошибка сервера, обновляем данные...</>,
            loading: false,
          })
        }
      })
  }

  renderReports = () => {
    const rows = this.state?.data?.map((item) => {
      return (
        <tr key={item?.id}>
          <td className="reports-table-td">{item?.id}</td>
          <td className="reports-some-td reports-table-td">{serverTime(item?.created_at)}</td>
          <td className="reports-some-td reports-table-td">
            {item?.status === 'Finished' ? serverTime(item?.completed_at) : '-'}
          </td>
          <td className="reports-some-td reports-table-td">
            {item.status === 'Finished' ? reportCompletedIn(item?.completed_at, item?.created_at) : '-'}
          </td>
          <td className="reports-table-td">{item?.price_rows_count}</td>
          <td className="reports-table-td">
            <div>{statusReport(item.status)}</div>
          </td>
          <td className="">
            {item?.status === 'Finished' && item?.reports[0]?.status === 'Error' ? (
              'Ошибка'
            ) : item?.status === 'Finished' ? (
              <span
                type="button"
                className="reports-card-block-price-btn-download"
                onClick={() => downloadLink(item?.reports[0]?.file)}
              >
                Скачать
              </span>
            ) : (
              '-'
            )}
          </td>
        </tr>
      )
    })
    return rows
  }

  createTaskComponent = () => {
    createTask(this?.props?.baseUrl, this?.props?.match?.params?.id, this?.signal?.token)
      .then(() => {
        if (this?.start) {
          this.setState({
            errorMessage: null,
          })

          this.getCampaignTasks()
        }
      })
      .catch((e) => {
        if (this?.start) {
          this.setState({
            errorMessage: e?.errorMessage,
          })
        }
      })
  }

  activeModal = (modal = true) => {
    this.setState({
      modal: modal,
    })
  }

  navigationSendRequest = (page) => {
    this.start2 = false
    this.getCampaignTasks(page)
  }

  render() {
    if (this?.state?.loading === false) {
      if (this?.state?.message) {
        return (
          <div className="content-wrapper-message">
            <div className="cardHeader">
              <h3>{this?.state?.message}</h3>
            </div>
          </div>
        )
      } else if (!this?.state?.message && this.state.reportsIsTrue) {
        return (
          <div className="content-wrapper-reports">
            <Modal
              state={this.state.modal}
              activeModal={this.activeModal}
              text={this.state.modalText}
              action={() => this.createTaskComponent()}
            />

            <div className="header-report-position">
              {this.state.reportsIsTrue ? (
                <div className="header-report-btn">
                  <button type="button" className="btn btn-success rounded-0" onClick={() => this.activeModal()}>
                    Создать отчет
                  </button>
                </div>
              ) : null}

              <div className="header-report">Отчеты кампании: "{this?.state?.campaignName}"</div>
            </div>

            <AlertMessage message={this?.state?.errorMessage} onDismiss={this.onDismiss} variant="danger" />

            <table className="table table-striped table-css-reports">
              <thead>
                <tr>
                  <th className="reports-table-td" scope="col">
                    ID отчета
                  </th>
                  <th className="reports-table-td" scope="col">
                    Старт
                  </th>
                  <th className="reports-table-td" scope="col">
                    Завершен
                  </th>
                  <th className="reports-table-td" scope="col">
                    Завершен за
                  </th>
                  <th className="reports-table-td" scope="col">
                    Товаров
                  </th>
                  <th className="reports-table-td" scope="col">
                    Статус
                  </th>
                  <th className="" scope="col">
                    Отчет
                  </th>
                </tr>
              </thead>
              <tbody>{this?.state?.data ? this.renderReports() : null}</tbody>
            </table>

            {/* навигация */}
            {this.state.reportsIsTrue && this.state.navigation.maxPage >= 2 ? (
              <nav className="text-selection-none">
                <ul className="pagination pagination-flex-wrap">
                  {/* кнопка назад */}
                  {this.state.navigation.previous ? (
                    <li className="page-item">
                      <span
                        type="button"
                        className="page-link rounded-0"
                        onClick={() =>
                          this?.state?.navigation?.previous
                            ? this.navigationSendRequest(this?.state?.navigation?.current - 1)
                            : null
                        }
                      >
                        <span>Назад</span>
                      </span>
                    </li>
                  ) : null}

                  {/* на первую страницу */}
                  {this?.state?.navigation?.current !== 1 ? (
                    <li className="page-item">
                      <span type="button" className="page-link rounded-0" onClick={() => this.navigationSendRequest(1)}>
                        {1}
                      </span>
                    </li>
                  ) : null}

                  {/* номер текущей страницы */}
                  <li className="page-item">
                    <span
                      type="button"
                      className="page-link rounded-0 reports-navigation-active-page"
                      onClick={() => this.navigationSendRequest(this?.state?.navigation?.current)}
                    >
                      {this?.state?.navigation?.current}
                    </span>
                  </li>

                  {/* номер следующей страницы после текущей */}
                  {this?.state.navigation?.maxPage >= this?.state?.navigation?.current + 1 ? (
                    <li className="page-item">
                      <span
                        type="button"
                        className="page-link rounded-0"
                        onClick={() => this.navigationSendRequest(this?.state?.navigation?.current + 1)}
                      >
                        {this?.state?.navigation?.current + 1}
                      </span>
                    </li>
                  ) : null}

                  {/* номер следующей страницы после текущей и текущей + 1 */}
                  {this?.state?.navigation?.maxPage >= this?.state?.navigation?.current + 2 ? (
                    <li className="page-item">
                      <span
                        type="button"
                        className="page-link rounded-0"
                        onClick={() => this.navigationSendRequest(this?.state?.navigation?.current + 2)}
                      >
                        {this?.state?.navigation?.current + 2}
                      </span>
                    </li>
                  ) : null}

                  {/* кнопка вперед */}
                  {this?.state?.navigation.next ? (
                    <li className="page-item">
                      <span
                        type="button"
                        className="page-link rounded-0"
                        onClick={() =>
                          this?.state?.navigation?.next
                            ? this.navigationSendRequest(this?.state?.navigation?.current + 1)
                            : null
                        }
                      >
                        <span>Вперед</span>
                      </span>
                    </li>
                  ) : null}
                </ul>
              </nav>
            ) : null}
          </div>
        )
      } else
        return (
          <div className="content-wrapper-message">
            <div className="cardHeader">
              <h3>
                Отчетов в кампании еще нет,{' '}
                <Link
                  to={this?.props?.match?.url}
                  onClick={() => {
                    this.createTaskComponent()
                  }}
                >
                  создать отчет.
                </Link>
              </h3>
            </div>

            <AlertMessage message={this?.state?.errorMessage} onDismiss={this.onDismiss} variant="danger" />
          </div>
        )
    } else return null
  }
}

const CampaignReports = withRouter(CampaignReportsComponent)
export { CampaignReports }
