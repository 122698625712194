import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { postDemoCampaign } from '../../../../services/post-demo-campaign'
import { getMessageTg } from '../../../../services/get-message-tg'

const CampaignSelectComponent = ({ history, baseUrl }) => {
  const [loading, setLoading] = useState(false)
  const [postRequestStart, setPostRequestStart] = useState(false)
  const [message, setMessage] = useState('')
  const dataForMessage = useMemo(() => {
    const data = [
      'Создаем демо кампанию (10%)',
      'Загружаем демо прайс с товарами (30%)',
      'Задаем города для мониторинга (40%)',
      'Задаем расписание для мониторинга (60%)',
      'Наполняем кампанию демо отчетами (80%)',
      'Настройка завершена, обновляем страницу (100%)',
    ]
    return data
  }, [])
  const btnCreateCampaign = (type) => {
    const newCampaign = type === 'new'

    return newCampaign ? (
      <span
        type="button"
        className="campaign-card-block-price-btn-create-task-first"
        onClick={() =>
          history.push({
            pathname: '/campaign/price-format',
          })
        }
      >
        перейти
      </span>
    ) : (
      <span type="button" className="campaign-card-block-price-btn-create-task-first" onClick={() => setLoading(true)}>
        перейти
      </span>
    )
  }
  const btnCreateCampaignNew = btnCreateCampaign('new')
  const btnCreateCampaignDemo = btnCreateCampaign('demo')
  const redux = useSelector(({ user }) => {
    return {
      user: user.currentUser.email,
    }
  })
  const user = useMemo(() => {
    return redux.user
  }, [redux])
  const postUrl = useMemo(() => {
    return baseUrl
  }, [baseUrl])

  useEffect(() => {
    let start = true
    const cancelToken = axios.CancelToken.source()

    const messForTg = `Пользователь ${user} создал демо кампанию.`
    postRequestStart && getMessageTg(messForTg, cancelToken.token)
    postRequestStart &&
      postDemoCampaign(postUrl, cancelToken.token).catch((e) => {
        start && setMessage(e.errorMessage)
      })
    return () => {
      cancelToken.cancel()
      start = false
    }
  }, [postRequestStart, postUrl, user])

  useEffect(() => {
    if (!loading) return

    let timer = 0
    const timerId = []
    for (let i = 0; i < dataForMessage.length + 1; i++) {
      if (i !== dataForMessage.length) {
        timerId[i] = setTimeout(() => {
          setMessage(dataForMessage[i])
        }, timer)
      }
      if (i === dataForMessage.length) {
        timerId[i] = setTimeout(() => {
          setPostRequestStart(true)
        }, timer - 2000)
      }
      timer += 3000
    }

    return () => {
      for (let i = 0; i < dataForMessage.length + 1; i++) {
        clearTimeout(timerId[i])
      }
    }
  }, [loading, dataForMessage])

  return !loading ? (
    <div className="content-wrapper">
      <div className="campaign-select">
        <div className="campaign-select__header">
          <span>Кампаний еще нет, выберите действие:</span>
        </div>
        <div className="campaign-select__body">
          <div>
            <span>1) У меня есть прайс с товарами, я самостоятельно создам кампанию для мониторинга - </span>
            {btnCreateCampaignNew}
          </div>
          <div>
            <span>
              2) У меня нет прайса с товарами, создать демо кампанию для ознакомления с возможностями сервиса -{' '}
            </span>
            {btnCreateCampaignDemo}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="content-wrapper-message">
      <div className="cardHeader" style={{ display: 'inline-block', marginRight: 5 }}>
        <h3>{message}</h3>
      </div>
    </div>
  )
}

const CampaignSelect = withRouter(CampaignSelectComponent)
export { CampaignSelect }
