import React from 'react'
import { withRouter, Link } from 'react-router-dom'

const priceFormatComponent = (props) => {
  return (
    <>
      <div className="content-wrapper campaign-create">
        <div className="cardHeader">1. Выберите формат прайса для мониторинга</div>
        <div className="card-group">
          <div className="card rounded-0">
            <img
              src="/img/link-45deg.svg"
              width="250"
              height="250"
              className="card-img-top"
              alt="прайс по ссылке"
            ></img>
            <div className="card-body">
              <h5 className="card-title">Прайс по ссылке</h5>
              <p className="card-text">Ссылка на файл с перечнем ваших товаров</p>
            </div>
            <div className="bg-info cardFooter rounded-0">
              <Link
                to={
                  props?.match?.params?.id
                    ? `/campaign/${props?.match?.params?.id}/price-url-upload`
                    : '/campaign/price-url-upload'
                }
              >
                <button className="btn btn-light" value="Перейти к загрузке">
                  Перейти к загрузке
                </button>
              </Link>
            </div>
          </div>
          <div className="card rounded-0">
            <img
              src="/img/file-earmark-bar-graph.svg"
              width="250"
              height="250"
              className="card-img-top fileicon"
              alt="прайс файлом"
            ></img>
            <div className="card-body">
              <h5 className="card-title">Прайс файлом</h5>
              <p className="card-text">Файл с перечнем ваших товаров</p>
            </div>
            <div className="bg-info cardFooter rounded-0">
              <Link
                to={
                  props?.match?.params?.id
                    ? `/campaign/${props?.match?.params?.id}/price-file-upload`
                    : '/campaign/price-file-upload'
                }
              >
                <button className="btn btn-light" value="Перейти к загрузке">
                  Перейти к загрузке
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const priceFormat = withRouter(priceFormatComponent)

export { priceFormat }
