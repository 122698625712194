import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { AlertMessage } from '../../../../../plugins/alert-message'

class PriceUrlComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      url: '',
      message: '',
      disabled: true,
    }
  }

  onDismiss = () => {
    this.setState({
      message: '',
    })
  }

  changeUrl = (url) => {
    const urlCheck = url?.split('/')[0]

    if (urlCheck === 'https:' || urlCheck === 'http:') {
      this.setState({
        url: url.substring(0, 1000),
        disabled: false,
        message: '',
      })
    } else {
      this.setState({
        url: url.substring(0, 1000),
        disabled: true,
        message: 'Задана некорректная ссылка на прайс.',
      })
    }
  }

  handleSubmitUrl = () => {
    if (this.state?.url.length > 0 && !this.state?.disabled) {
      this.props.history.push({
        pathname: '/campaign/price/column-matching',
        state: {
          url: this?.state?.url,
          id_campaign: this?.props?.match?.params?.id || null,
        },
      })
    }
  }

  render() {
    return (
      <>
        <div className="content-wrapper">
          <div className="cardHeader">2. Загрузка прайса по ссылке</div>

          <AlertMessage message={this.state.message} onDismiss={this.onDismiss} variant="danger" />

          <div className="card rounded-0">
            <div className="card-body">
              <input
                className="form-control form-control-sm"
                type="text"
                placeholder="Ссылка на прайс"
                value={this.state.url}
                onChange={(e) => this.changeUrl(e?.target?.value.trim())}
              />
            </div>
            <div className="bg-info cardFooter rounded-0">
              <button
                className={this.state.disabled ? 'btnDisabled btn btn-light' : 'btn btn-light'}
                variant="info"
                type="submit"
                onClick={this.handleSubmitUrl}
                value="Отправить"
              >
                Отправить
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const PriceUrl = withRouter(PriceUrlComponent)
export { PriceUrl }
