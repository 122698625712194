import React from 'react'
import axios from 'axios'

import { reloadPage } from '../../plugins/reload-page'

const postMessage = (baseUrl, formData, signalToken) => {
    const postMessage = new Promise(function(resolve, reject) {

        const url = `${baseUrl}/api/user/credentials`

        axios.post(url, formData, {
            cancelToken: signalToken,
            headers: { Authorization: `Bearer ${localStorage?.getItem('access')}` }
        })
        .then((res) => {
            if (res?.status === 201 && res?.data?.success) {
                resolve(res)
            } else throw new Error()
        })
        .catch((e) => {
            if (e?.response?.status === 401) {
                reloadPage(e)
                return
            }
    
            const errorMessage = <>Ошибка сервера, обратитесь в чат для запроса счета.</>
            reject({errorMessage: errorMessage, serverMessage: e?.message})
        })
    })
    return postMessage
}

export { postMessage }