// константы с именем типа actions
const ADMIN_USERS_SEARCH_INPUT_TEXT = 'ADMIN_USERS_SEARCH_INPUT_TEXT'

// дефолтное состояние редюсера
const defaultState = {
  adminUsersSearchInputText: '',
}

export const actionAdminUsersSearchInputText = (inputText = defaultState?.adminUsersSearchInputText) => {
  return {
    type: ADMIN_USERS_SEARCH_INPUT_TEXT,
    payload: inputText, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

// создание и экспорт редюсера   // состояние            // экшен
export default function adminReducer(state = defaultState, action) {
  // в зависимости от экшена выполняем действие
  switch (action.type) {
    case ADMIN_USERS_SEARCH_INPUT_TEXT:
      return {
        ...state, // возвращаем текущее состояние и изменяем одно поле
        adminUsersSearchInputText: action.payload,
      }

    // свитч который возвращает дефолтное состояние (обязательно)
    // default: return state
    default:
      return state
  }
}
