import React from 'react'
import { Link } from 'react-router-dom'

const successPay = () => {

    return (
        <div className="content-wrapper-message">
            <div className="cardHeader">
                <h3>Оплата успешна, перейти <Link to="/campaigns">в мои кампании.</Link></h3>
            </div>
        </div>
    )
}

export { successPay }