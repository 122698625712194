import axios from 'axios'
import { reloadPage } from '../../plugins/reload-page'

const createTask = (baseUrl, id, signalToken) => {
  const createTask = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/campaign/${id}/task`
    const obj = {}

    axios
      .post(url, obj, {
        cancelToken: signalToken,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 201) {
          resolve()
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        if (e?.response?.status === 404) {
          const errorMessage = 'Кампания не существует или была удалена.'
          reject({ errorMessage: errorMessage })
          return
        }

        if (e?.response?.status === 403) {
          const errorMessage = 'Недостаточно проверок для создания отчета.'
          reject({ errorMessage: errorMessage })
          return
        }

        if (e?.response?.status === 400 && e?.response?.data?.price_status) {
          if (e?.response?.data?.price_status === 'File Upload') {
            reject({ errorMessage: 'Ошибка, в кампании загружается прайс...' })
            return
          }

          if (e?.response?.data?.price_status === 'Collecting') {
            reject({ errorMessage: 'Ошибка, в кампании обрабатывается прайс...' })
            return
          }

          if (e?.response?.data?.price_status === 'Column Matching') {
            reject({ errorMessage: 'Ошибка, не заданы колонки для прайса в кампании.' })
            return
          }

          if (e?.response?.data?.price_status === 'Error') {
            reject({ errorMessage: 'Ошибка прайса, загрузите прайс в кампанию повторно.' })
            return
          }
        }

        const errorMessage = 'Ошибка сервера, попробуйте повторить действие.'
        reject({ errorMessage: errorMessage })
      })
  })
  return createTask
}

export { createTask }
