import React from 'react'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'

import { AlertMessage } from '../../../../plugins/alert-message'
import { reloadPage } from '../../../../plugins/reload-page'
import './5-campaign-region-select.css'

class RegionSelectComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      regions: [],
      activeRegions: [],
      inputText: '',
      message: null,
      messageError: null,
      loading: true,
    }
  }

  componentDidMount() {
    // отмена запросов
    this.signal = axios.CancelToken.source()
    this.start = true

    // проверка на валидность кампании
    this.checkCampaign()
  }

  componentWillUnmount() {
    this.signal.cancel()
    this.start = false
  }

  onDismiss = () => {
    this.setState({
      message: null,
    })
  }

  checkCampaign = () => {
    const url = `${this?.props?.baseUrl}/api/campaign/${this?.props?.match?.params?.id}`

    axios
      .get(url, {
        cancelToken: this.signal.token,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error()
        }

        if (this.start && res?.status === 200) {
          if (this.props?.consoleDebug) {
            console.log('9) Кампания существует, загружаем города')
          }

          // получение городов маркета, принимает ранее внесенные города
          this.getRegions(res?.data?.regions)

          this.setState({
            loading: false,
          })
          return
        }
      })
      .catch((e) => {
        if (this.start && e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        if (this.start && e?.response?.status === 403) {
          if (this.props?.consoleDebug) {
            console.log('9) Кампания не существует.')
          }

          this.setState({
            loading: false,
            messageError: (
              <>
                Кампания не существует, перейти в <Link to="/campaigns">мои кампании.</Link>
              </>
            ),
          })
          return
        }

        if (this.start) {
          this.setState({
            loading: false,
            messageError: (
              <>
                Ошибка при проверке кампании,
                <br />
                попробуйте обновить страницу
                <br />
                или обратитесь в поддержку.
              </>
            ),
          })
          return
        }
      })
  }

  getRegions = (savedRegions) => {
    const url = `${this?.props?.baseUrl}/api/site/1/regions`

    axios
      .get(url, {
        cancelToken: this.signal.token,
        headers: { Authorization: `Bearer ${localStorage?.getItem('access')}` },
      })
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error()
        }

        if (this.start) {
          if (this.props?.consoleDebug) {
            console.log('10) Города загружены успешно')
          }

          // добавили в каждый объект флаг display
          const arr = res?.data.map((item) => {
            return { ...item, display: true }
          })

          // если есть ранее внесенные города, ставим для них флаг display = false
          if (savedRegions?.length > 0) {
            savedRegions.forEach((item) => {
              arr.forEach((region, index) => {
                if (item === region.id) {
                  arr[index].display = false
                }
              })
            })
          } else {
            // задаем город по умолчанию для новых кампаний
            arr.forEach((item, index) => {
              if (item.name === 'Москва') {
                arr[index].display = false
              }
            })
          }

          // создаем выборку для активных городов
          let arr2 = JSON.parse(JSON.stringify(arr)).filter((item) => {
            return item.display === false
          })

          this.setState({
            regions: arr,
            activeRegions: arr2,
            message: null,
          })
        }
      })
      .catch((e) => {
        if (this.start && e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        if (this.start) {
          this.setState({
            message: 'Произошла ошибка загрузки городов, обратитесь в поддержку.',
          })
        }
      })
  }

  renderAllRegions = (regionsArr) => {
    return regionsArr.map((item) => {
      let css
      if (item.display) {
        css = 'list-group-item custom-border custom-border-bottom'
      } else {
        css = 'list-group-item custom-border custom-border-bottom display-false'
      }

      return (
        <li className={css} key={item.id}>
          <button
            type="button"
            className="btn btn-success btn-trash bi bi-plus-lg custom-btn-region-add"
            value={item.id}
            name={item.name}
            onClick={(e) => this.addRegion(e.target)}
          ></button>
          {item.name}
        </li>
      )
    })
  }

  renderActiveRegions = () => {
    const newArr = this.state.regions.filter((item) => {
      return item.display === false
    })

    return this.sort(newArr).map((item, index) => {
      return (
        <li className="list-group-item custom-border custom-border-bottom" key={index}>
          <button
            type="button"
            className="btn btn-danger btn-trash bi bi-x-lg custom-btn-region-del"
            value={item.id}
            name={item.name}
            onClick={(e) => this.deleteRegion(e.target)}
          ></button>
          {item.name}
        </li>
      )
    })
  }

  addRegion = (item) => {
    const objRegion = { id: Number(item.value), name: item.name, display: false }

    this.setState((state) => {
      const arr = state.regions.filter((region) => {
        return region.id !== Number(item.value)
      })

      return {
        activeRegions: [...state.activeRegions, objRegion],
        regions: [...arr, objRegion],
        message: state.message === 'Не выбран город мониторинга.' ? null : state.message,
      }
    })
  }

  deleteRegion = (item) => {
    const objRegion = { id: Number(item.value), name: item.name, display: true }

    this.setState((state) => {
      const arrAll = state.regions.filter((region) => {
        return region.id !== Number(item.value)
      })

      const arrActive = state.activeRegions.filter((region) => {
        return region.id !== Number(item.value)
      })

      return {
        regions: [...arrAll, objRegion],
        activeRegions: arrActive,
      }
    })
  }

  // сортировка от А до Я
  sort = (arr) => {
    arr.sort((a, b) => {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()

      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return arr
  }

  inputOnChange = (inputText) => {
    const arr = this.state.regions.filter((item) => {
      const regionName = item.name.toLowerCase()
      return regionName.indexOf(inputText) > -1
    })
    return arr
  }

  renderSearch = () => {
    return (
      <label className="searchInputRegion">
        <input
          value={this.state.inputText}
          className="searchInputRegion"
          placeholder="Поиск города"
          type="text"
          onChange={(e) => {
            this.setState({
              inputText: e?.target?.value.toLowerCase(),
            })
          }}
        />
      </label>
    )
  }

  countActiveRegions = () => {
    if (this?.state?.activeRegions?.length === 0) {
      return false
    } else {
      return true
    }
  }

  saveButton = () => {
    if (this.countActiveRegions() > 0 && this.start) {
      let obj = { regions: [] }
      this.state.activeRegions.forEach((item) => {
        obj.regions.push(item.id)
      })

      const url = `${this?.props?.baseUrl}/api/campaign/${this?.props?.match?.params?.id}/set-regions`

      if (this.props?.consoleDebug) {
        console.log('11) Передаем выбранные города:')
        console.log(obj)
      }

      axios
        .post(url, obj, {
          cancelToken: this.signal.token,
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('access')}`,
            'X-User': localStorage.getItem('selectedUserId') || null,
          },
        })
        .then((res) => {
          if (res?.status !== 200) {
            throw new Error()
          }

          if (this.start && res?.status === 200) {
            if (this.props?.consoleDebug) {
              console.log(`12) Получен ответ от сервера: ${res?.data?.message}`)
              console.log(
                `13) Перенаправляем на страницу: /campaign/${this?.props?.match?.params?.id}/settings/setup-completed`
              )
            }
            this.props.history.push({
              pathname: `/campaign/${this?.props?.match?.params?.id}/settings/setup-completed`,
            })
          }
        })
        .catch((e) => {
          if (this.start && e?.response?.status === 401) {
            reloadPage(e)
            return
          }

          if (this.start) {
            this.setState({
              message: 'Ошибка сохранения городов, попробуйте еще раз.',
            })
          }
        })
    } else {
      this.setState({
        message: 'Не выбран город мониторинга.',
      })
    }
  }

  clearButton = () => {
    return (
      <li className="list-group-item custom-border custom-border-bottom">
        Совпадений не найдено,{' '}
        <span
          onClick={() => {
            this.setState({ inputText: '' })
          }}
          type="button"
          className="campaign-region-clear-button"
        >
          очистить поиск.
        </span>
      </li>
    )
  }

  render() {
    if (!this.state.loading) {
      if (this.state.messageError) {
        return (
          <div className="content-wrapper-message">
            <div className="cardHeader">
              <h3>{this.state.messageError}</h3>
            </div>
          </div>
        )
      } else {
        const regions = this.renderAllRegions(this.sort(this.inputOnChange(this.state.inputText)))

        // проверяем все ли строки активные
        let x = 0
        regions?.forEach((item) => {
          if (item?.props?.className === 'list-group-item custom-border custom-border-bottom') {
            x = ++x
          }
        })
        const regionsEmpty = x === 0 ? true : false

        return (
          <>
            <div className="content-wrapper">
              <div className="cardHeader">4. Выберите город(a) мониторинга</div>

              <AlertMessage message={this.state.message} onDismiss={this.onDismiss} variant="danger" />

              <div className="card-group">
                <div className="card rounded-0">
                  <div className="search-region">{this.renderSearch()}</div>

                  <ul className="list-group rounded-0 regionsScroll">
                    {regionsEmpty && this.state.regions?.length > 0 ? this.clearButton() : regions}
                  </ul>
                </div>

                <div className="card rounded-0">
                  <div className="search-region-value-title">
                    <span>Выбранные города:</span>
                  </div>

                  <ul className="list-group rounded-0 regionsScroll">{this.renderActiveRegions()}</ul>
                </div>
              </div>

              <div className="card rounded-0">
                <div className="bg-info cardFooter rounded-0">
                  <button className="btn btn-light" type="submit" onClick={this.saveButton}>
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }
    } else {
      return null
    }
  }
}

const RegionSelect = withRouter(RegionSelectComponent)
export { RegionSelect }
