import axios from 'axios'
import { setUser } from '../../reducers/user-reducer'

// Redux Thunk это middleware библиотека, которая позволяет вам вызвать ACTION-creator,
// возвращая при этом функцию вместо объекта. Функция принимает метод dispatch как аргумент,
// чтобы после того, как асинхронная операция завершится, использовать его для диспатчинга
// обычного синхронного экшена, внутри тела функции.

// асинхронный ACTION
const login = (email, password, baseUrl, signalToken) => {
  return async (dispatch) => {
    try {
      // отправляем данные, в ответ два токена refresh и access
      const url = `${baseUrl}/api/auth/jwt/create/`
      const response = await axios
        .post(
          url,
          {
            email,
            password,
          },
          {
            cancelToken: signalToken,
          }
        )
        .then(async (res) => {
          // храним токен, полученный от сервера
          localStorage.setItem('refresh', res.data.refresh)
          localStorage.setItem('access', res.data.access)

          // отправляем токен access, в ответе данные по пользователю
          const url = `${baseUrl}/api/auth/users/me/`
          const response = await axios.get(url, {
            cancelToken: signalToken,
            headers: { Authorization: `Bearer ${res.data.access}` },
          })

          return response
        })

      // вызываем dispatch и передаем ACTION-creator, где в параметрах полученный user
      // response.data = { "username": "name","id": 1,"email": "user@gmail.com" }

      //       <<< ----
      // ACTION from ACTION-creator (вернет объект(ACTION))
      // { type: SET_USER, payload: { "username": "name","id": 1,"email": "user@gmail.com" } }

      // dispatch отправит экшен в reducer, где будет вызван case SET_USER
      // где получим измененного пользователя и параметр отвечающий за авторизацию
      // { currentUser: { "username": "name","id": 1,"email": "user@gmail.com" }, isAuth: true }
      dispatch(setUser(response.data))

      return response
    } catch (e) {
      // if (axios.isCancel(e)) {
      //     console.log('Error: ', e.message); // => prints: Api is being canceled
      // }

      localStorage.clear()

      return e.response
    }
  }
}

export { login }
