const serverTime = (time) => {
    // первая часть
    const x = time.split('T')
    const x1 = x[0].split('-')
    const x2 = x1[2] + '.' + x1[1] + '.' + x1[0]

    // вторя часть
    const y = x[1].split('.')
    const y1 = (y[0].split(':'))
    const y2 = y1[0] + ':' + y1[1]

    return x2 + ', ' + y2
}

export { serverTime }