import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import { RenderReports } from './renderReports'
import { getAllReports } from '../../../services/get-all-reports'
import { Navigation } from './navigation'
import './campaigns-reports.css'

const CampaignsReports = (props) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [secondLoading, setSecondLoading] = useState(false)
  const [message, setMessage] = useState()
  const [navigation, setNavigation] = useState()
  const [currentPageNum, setCurrentPageNum] = useState(1)

  const getData = useCallback(
    (signal, start, page) => {
      if (!page) return

      getAllReports(props?.baseUrl, page, signal.token)
        .then((res) => {
          if (start && res?.data?.results?.length === 0) {
            setLoading(false)
            setMessage()
            setSecondLoading(true)
            return
          }

          if (start && res?.data?.results?.length > 0) {
            setLoading(false)
            setMessage()
            setSecondLoading(true)
            setData(res?.data)

            setNavigation({
              maxPage: Math.ceil(res?.data?.count / 10),
              previous: res?.data?.previous ? true : false,
              current: currentPageNum,
              next: res?.data?.next ? true : false,
            })
          }
        })
        .catch((e) => {
          if (start) {
            setMessage(e?.errorMessage)
            setLoading(false)
            setSecondLoading(false)
          }
        })
    },
    [currentPageNum, props?.baseUrl]
  )

  const updatePage = useCallback(
    (signal, start, page) => {
      const timerId = setInterval(() => {
        getData(signal, start, page)
      }, 2000)
      return timerId
    },
    [getData]
  )

  useEffect(() => {
    const signal = axios.CancelToken.source()
    let start = true

    getData(signal, start, currentPageNum)
    const timerId = updatePage(signal, start, currentPageNum)

    return () => {
      signal.cancel()
      start = false
      clearInterval(timerId)
    }
  }, [getData, updatePage, currentPageNum])

  if (!loading) {
    if (message && !secondLoading) {
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            <h3>{message}</h3>
          </div>
        </div>
      )
    }

    if (!message && data === undefined) {
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            <h3>
              {' '}
              Отчетов еще нет, перейти в <Link to="/campaigns">мои кампании.</Link>
            </h3>
          </div>
        </div>
      )
    }

    return (
      <div className="content-wrapper-all-reports">
        <div className="cardHeader">Мои отчеты</div>

        <table className="table table-striped table-css-campaigns-reports">
          <thead>
            <tr>
              <th className="reports-table-td" scope="col">
                ID кампании
              </th>
              <th className="reports-table-td" scope="col">
                ID отчета
              </th>
              <th className="reports-table-td" scope="col">
                Старт
              </th>
              <th className="reports-table-td" scope="col">
                Завершен
              </th>
              <th className="reports-table-td" scope="col">
                Завершен за
              </th>
              <th className="reports-table-td" scope="col">
                Товаров
              </th>
              <th className="reports-table-td" scope="col">
                Статус
              </th>
              <th className="" scope="col">
                Отчет
              </th>
            </tr>
          </thead>
          <tbody>
            <RenderReports data={data} />
          </tbody>
        </table>

        <Navigation navigation={navigation} setCurrentPageNum={(num) => setCurrentPageNum(num)} />
      </div>
    )
  } else return null
}

export { CampaignsReports }
