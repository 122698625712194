import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const HomePage = (props) => {
  const isAuthAdmin = useSelector(({ user }) => user.currentUser.is_staff)
  const openPage = localStorage.getItem('openPage')

  if (openPage) {
    localStorage.removeItem('openPage')
    return <Redirect to={openPage} />
  }

  if (props.checkSignIn && isAuthAdmin) return <Redirect to="/users" />

  if (props.checkSignIn && !isAuthAdmin) return <Redirect to="/campaigns" />

  return null
}
export { HomePage }
