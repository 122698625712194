const declOfNum = (n, text_forms) => {  
    n = Math.abs(n) % 100; const n1 = n % 10
    if (n > 10 && n < 20) { return text_forms[2] }
    if (n1 > 1 && n1 < 5) { return text_forms[1] }
    if (n1 === 1) { return text_forms[0] }
    return text_forms[2]
}

const reportCompletedIn = (completed, created) => {
    const completedIn = ((Date.parse(completed) - Date.parse(created)) / 1000).toString().split('.')[0]
    if (completedIn < 60) {
        const sec = Math.floor(completedIn)
        const text = declOfNum(sec, [' секунду', ' секунды', ' секунд'])
        return sec + text
    } else {
        const min = (completedIn / 60).toString().split('.')[0]
        const sec = (completedIn - (min * 60))
        const text = (declOfNum(min, [' минуту', ' минуты', ' минут']))

        if (sec === 0) {
            return (min + text)
        } else {
            return (min + text + ', ' + sec + ' сек.')
        }
    }
}

export { reportCompletedIn }