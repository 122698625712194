import axios from 'axios'

const registration = async (username, email, password, baseUrl, signalToken) => {
  try {
    const url = `${baseUrl}/api/auth/users/`

    const response = await axios.post(
      url,
      {
        username,
        email,
        password,
      },
      {
        cancelToken: signalToken,
      }
    )
    return response
  } catch (e) {
    // if (axios.isCancel(e)) {
    //     console.log('Error: ', e.message); // => prints: Api is being canceled
    // }

    return e.response ? e.response : e
  }
}
export { registration }
