import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import { reloadPage } from '../../plugins/reload-page'

const getPlans = (baseUrl, signalToken) => {
  const plans = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/plans/`

    axios
      .get(url, {
        cancelToken: signalToken,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        const errorMessage = (
          <>
            Ошибка загрузки данных, перейти в <Link to="/campaigns">мои кампании.</Link>
          </>
        )
        reject({ errorMessage: errorMessage })
      })
  })
  return plans
}

export { getPlans }
