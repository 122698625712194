import React from 'react'

const Navigation = (props) => {
  const navigation = props?.navigation

  if (navigation?.maxPage >= 2) {
    return (
      <nav className="text-selection-none">
        <ul className="pagination pagination-flex-wrap">
          {/* кнопка назад */}
          {navigation?.previous ? (
            <li className="page-item">
              <span
                type="button"
                className="page-link rounded-0"
                onClick={() => (navigation?.previous ? props?.setCurrentPageNum(navigation?.current - 1) : null)}
              >
                <span>Назад</span>
              </span>
            </li>
          ) : null}

          {/* на первую страницу */}
          {navigation?.current !== 1 ? (
            <li className="page-item">
              <span type="button" className="page-link rounded-0" onClick={() => props?.setCurrentPageNum(1)}>
                {1}
              </span>
            </li>
          ) : null}

          {/* номер текущей страницы */}
          <li className="page-item">
            <span
              type="button"
              className="page-link rounded-0 reports-navigation-active-page"
              onClick={() => props?.setCurrentPageNum(navigation?.current)}
            >
              {navigation?.current}
            </span>
          </li>

          {/* номер следующей страницы после текущей */}
          {navigation?.maxPage >= navigation?.current + 1 ? (
            <li className="page-item">
              <span
                type="button"
                className="page-link rounded-0"
                onClick={() => props?.setCurrentPageNum(navigation?.current + 1)}
              >
                {navigation?.current + 1}
              </span>
            </li>
          ) : null}

          {/* номер следующей страницы после текущей и текущей + 1 */}
          {navigation.maxPage >= navigation?.current + 2 ? (
            <li className="page-item">
              <span
                type="button"
                className="page-link rounded-0"
                onClick={() => props?.setCurrentPageNum(navigation?.current + 2)}
              >
                {navigation?.current + 2}
              </span>
            </li>
          ) : null}

          {/* кнопка вперед */}
          {navigation?.next ? (
            <li className="page-item">
              <span
                type="button"
                className="page-link rounded-0"
                onClick={() => (navigation?.next ? props?.setCurrentPageNum(navigation?.current + 1) : null)}
              >
                <span>Вперед</span>
              </span>
            </li>
          ) : null}
        </ul>
      </nav>
    )
  } else return null
}

export { Navigation }
