import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { CardHeader } from './Сard-header'
import { CardBody } from './Card-body'
import { CardFooter } from './Card-footer'
import { AlertMessage } from '../../../plugins/alert-message'

import { getCampaignSetting } from '../../../services/get-campaign-setting'

import './campaign-settings.css'

function ComponentCampaignSettings(props) {
  const cancelRequest = useRef(null)
  const loading = useRef(true)
  const [tabs, setTabs] = useState({
    main: true,
    cities: false,
    autoStart: false,
  })
  const [campaignSettings, setCampaignSettings] = useState()
  const [serverMessageError, setServerMessageError] = useState()
  const [errorMessage, setErrorMessage] = useState(null)

  const [autoStart, setAutoStart] = useState({
    mo: { is_enabled: true, times: [] },
    tu: { is_enabled: false, times: [] },
    we: { is_enabled: false, times: [] },
    th: { is_enabled: false, times: [] },
    fr: { is_enabled: false, times: [] },
    sa: { is_enabled: false, times: [] },
    su: { is_enabled: false, times: [] },
  })

  useEffect(() => {
    if (props.location.hash === '#planner') {
      setTabs({
        main: false,
        cities: false,
        autoStart: true,
      })

      window.history.pushState('', document.title, window.location.pathname)
    }
  }, [props.location.hash])

  useEffect(() => {
    if (cancelRequest.current == null) {
      cancelRequest.current = axios.CancelToken.source()
    }

    getCampaignSetting(props?.baseUrl, props?.match?.params?.id, cancelRequest.current?.token)
      .then((res) => {
        if (loading.current) {
          loading.current = false

          setCampaignSettings({
            id: res?.data?.id,
            name: res?.data?.name,
            price_min: res?.data?.price_min,
            region_names: res?.data?.region_names,
            search_with_filters: res?.data?.search_with_filters,
          })

          setAutoStart((data) => {
            return {
              ...data,
              mo: res?.data?.auto_start?.mo ? { ...res?.data?.auto_start?.mo } : { ...data.mo },
              tu: res?.data?.auto_start?.tu ? { ...res?.data?.auto_start?.tu } : { ...data.tu },
              we: res?.data?.auto_start?.we ? { ...res?.data?.auto_start?.we } : { ...data.we },
              th: res?.data?.auto_start?.th ? { ...res?.data?.auto_start?.th } : { ...data.th },
              fr: res?.data?.auto_start?.fr ? { ...res?.data?.auto_start?.fr } : { ...data.fr },
              sa: res?.data?.auto_start?.sa ? { ...res?.data?.auto_start?.sa } : { ...data.sa },
              su: res?.data?.auto_start?.su ? { ...res?.data?.auto_start?.su } : { ...data.su },
            }
          })
        }
      })
      .catch((e) => {
        if (loading.current) {
          loading.current = false
          setServerMessageError(e?.errorMessage)
        }
      })

    return () => {
      cancelRequest.current.cancel()
      loading.current = false
    }
  }, [props?.baseUrl, props?.match?.params?.id])

  const onDismiss = () => {
    setErrorMessage(null)
  }

  if (!loading.current) {
    if (serverMessageError) {
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            <h3>{serverMessageError}</h3>
          </div>
        </div>
      )
    } else
      return (
        <div className="content-wrapper">
          <div className="cardHeader long-name-campaign-settings">Настройки кампании</div>

          <AlertMessage message={errorMessage} onDismiss={onDismiss} variant="danger" />

          <div className="campaign-settings-block">
            <div className="card text-center campaign-settings-card rounded-0">
              <CardHeader tabs={tabs} setTabs={setTabs} campaignId={campaignSettings?.id} />

              <CardBody
                baseUrl={props?.baseUrl}
                loading={loading.current}
                signalToken={cancelRequest.current?.token}
                tabs={tabs}
                campaignSettings={campaignSettings}
                autoStart={autoStart}
                setCampaignSettings={setCampaignSettings}
                setAutoStart={setAutoStart}
                setErrorMessage={setErrorMessage}
              />

              <CardFooter
                baseUrl={props?.baseUrl}
                loading={loading.current}
                signalToken={cancelRequest.current?.token}
                campaignSettings={campaignSettings}
                autoStart={autoStart}
                setAutoStart={setAutoStart}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </div>
        </div>
      )
  } else return null
}

const CampaignSettings = withRouter(ComponentCampaignSettings)
export { CampaignSettings }
