import React, { useEffect, useRef } from 'react'
import data from './data.json'
import './style.css'

import { Qitem } from './Qitem'

const Questions = ({ priceXML, priceEXCEL }) => {
  const items = data?.data
  const sidenavBlock = useRef()
  const accordionBlock = useRef()

  const resizeSidenav = () => {
    const accordion = Number(getComputedStyle(accordionBlock.current).height.split('px')[0])
    const width = document.documentElement.clientWidth

    if (width > 991) {
      if (accordion > 840) {
        sidenavBlock.current[0].style['min-height'] = accordion + 100 + 'px'
      } else {
        sidenavBlock.current[0].style['min-height'] = 840 + 'px'
      }
    }
  }
  const resizeMenu = () => {
    const width = document.documentElement.clientWidth

    if (width < 992) {
      const checkMinHeight = Number(sidenavBlock.current[0].style['min-height'].split('px')[0]) > 0
      checkMinHeight && sidenavBlock.current[0].style.removeProperty('min-height')
    }
  }

  useEffect(() => {
    sidenavBlock.current = document.getElementsByClassName('sidenav')
    accordionBlock.current = document.getElementById('accordionBlock')

    window.addEventListener('scroll', resizeSidenav)
    window.addEventListener('resize', resizeMenu)
    return () => {
      window.removeEventListener('scroll', resizeSidenav)
      window.removeEventListener('resize', resizeMenu)
    }
  }, [])

  return (
    <div className="content-wrapper">
      <div className="questions-card-header">Часто задаваемые вопросы</div>
      <div className="accordion accordion-flush" id="accordionBlock">
        {items && !items.length && <h3>Вопросов еще нет, необходимо их добавить...</h3>}
        {items &&
          !!items.length &&
          items.map((item) => <Qitem item={item} key={item.title} priceXML={priceXML} priceEXCEL={priceEXCEL} />)}
      </div>
    </div>
  )
}

export { Questions }
