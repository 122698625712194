import React, { useState, useEffect, useRef, useCallback } from 'react'
import axios from 'axios'

import { useSelector, useDispatch } from 'react-redux'

import { actionAdminUsersSearchInputText } from '../../../reducers/admin-reducer'

import { getUsers } from '../../../services/admin/get-users'
import { RenderUsers } from './render-users'
import { usersNav } from './users-nav'

import './users.css'

const Users = (props) => {
  const dispatch = useDispatch()

  const baseUrl = useRef(props.baseUrl)
  const cancelRequest = useRef(null)
  const didmount = useRef(true)
  const firstLoadNav = useRef(true)
  const refData = useRef(null)

  const [, setData] = useState()
  const [dataFilter, setDataFilter] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [usersEmpty, setUsersEmpty] = useState(false)

  const [nav, setNav] = useState({
    itemsPerPage: 10,
    maxPages: null,
    currentPage: 1,
    previousPage: false,
    nextPage: false,
  })

  const redux = useSelector((state) => {
    return {
      searchInputText: state.admin.adminUsersSearchInputText,
    }
  })

  const defaultState = useCallback(() => {
    firstLoadNav.current = true
    refData.current = null

    setDataFilter()
    dispatch(actionAdminUsersSearchInputText(''))
    setUsersEmpty(false)

    setNav((nav) => {
      return {
        ...nav,
        maxPages: null,
        currentPage: 1,
        previousPage: false,
        nextPage: false,
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (cancelRequest.current == null) {
      cancelRequest.current = axios.CancelToken.source()
    }

    function start() {
      getUsers(baseUrl.current, cancelRequest.current?.token)
        .then((res) => {
          if (didmount.current) {
            setErrorMessage()
            setData(res?.data)
            refData.current = res?.data

            if (firstLoadNav.current) {
              firstLoadNav.current = false
              setNav((nav) => {
                return {
                  ...nav,
                  maxPages: Math.ceil(res?.data?.length / nav.itemsPerPage),
                  nextPage: Math.ceil(res?.data?.length / nav.itemsPerPage) > 1 ? true : false,
                }
              })
            }
          }
        })
        .catch((e) => {
          if (didmount.current) {
            setErrorMessage(e?.errorMessage)
            defaultState()
          }
        })
    }
    start()
    const timerID = setInterval(start, 2000)

    return () => {
      cancelRequest.current.cancel()
      clearInterval(timerID)

      didmount.current = false
    }
  }, [defaultState])

  useEffect(() => {
    if (firstLoadNav.current) return
    if (redux.searchInputText === '') {
      clearSearchInputText()
      return
    }

    const emailFilter = (inputText) => {
      const dataFilter = refData?.current?.filter((item) => {
        return item?.email?.toLowerCase().indexOf(inputText?.toLowerCase()) > -1
      })
      return dataFilter
    }

    const idFilter = (inputText) => {
      const dataFilterId = refData?.current?.filter((item) => {
        return String(item?.id)?.toLowerCase() === inputText?.toLowerCase()
      })
      return dataFilterId
    }

    const emails = emailFilter(redux.searchInputText)
    const ids = idFilter(redux.searchInputText)
    const users = [...new Set([...emails, ...ids])]

    if (users?.length === 0) {
      setUsersEmpty(true)
    } else if (users?.length > 0) {
      setUsersEmpty(false)
    }

    setNav((nav) => {
      return {
        ...nav,
        maxPages: Math.ceil(users?.length / nav.itemsPerPage),
        nextPage: Math.ceil(users?.length / nav.itemsPerPage) > 1 ? true : false,
        currentPage: 1,
        previousPage: false,
      }
    })

    setDataFilter(users)
  }, [redux.searchInputText])

  const clickOnPage = (numPage) => {
    if (didmount.current) {
      setNav({
        ...nav,
        currentPage: numPage,
        previousPage: numPage === 1 ? false : true,
        nextPage: nav?.maxPages === numPage ? false : true,
      })
    }
  }

  const filterData = (data) => {
    // вычисляем индекс конечного item (при первой загрузке = 3)
    const lastPageIndex = nav?.currentPage * nav?.itemsPerPage

    // вычисляем индекс начального item (при первой загрузке = 0)
    const firstIndex = lastPageIndex - nav?.itemsPerPage

    // забирем нужный перечень items (от и до, при первой загрузке 0, 1, 2)
    const dataSlice = data.slice(firstIndex, lastPageIndex)

    return dataSlice
  }

  const renderSearch = () => {
    return (
      <div className="input-group mb-3">
        <input
          placeholder="Поиск пользователя"
          type="text"
          className="form-control rounded-0 input-search-users"
          value={redux.searchInputText}
          onChange={(e) => {
            // setSearchInputText(e?.target?.value?.trim())
            dispatch(actionAdminUsersSearchInputText(e?.target?.value?.trim()))
          }}
        />
      </div>
    )
  }

  const clearSearchInputText = () => {
    setDataFilter()
    setUsersEmpty(false)
    setNav((nav) => {
      return {
        ...nav,
        maxPages: Math.ceil(refData?.current?.length / nav.itemsPerPage),
        nextPage: Math.ceil(refData?.current?.length / nav.itemsPerPage) > 1 ? true : false,
        currentPage: 1,
        previousPage: false,
      }
    })
  }

  if (errorMessage) {
    return (
      <div className="content-wrapper-message">
        <div className="cardHeader">
          <h3>{errorMessage}</h3>
        </div>
      </div>
    )
  }

  if (!errorMessage && refData.current) {
    const users = filterData(dataFilter ? dataFilter : refData.current)

    return (
      <div className="users-content-wrapper">
        <div className="search-users-position">
          <div className="search-users">{renderSearch()}</div>
        </div>

        {!usersEmpty ? null : (
          <h5 className="users-not-found">
            Пользователей не найдено,{' '}
            <span
              type="button"
              className="users-btn-clear-input-text"
              onClick={() => dispatch(actionAdminUsersSearchInputText(''))}
            >
              очистить поиск.
            </span>
          </h5>
        )}

        {!usersEmpty ? (
          <table className="table table-striped users-table-css">
            <thead>
              <tr>
                <th className="users-table-td" scope="col"></th>
                <th className="users-table-td" scope="col">
                  Email
                </th>
                <th className="users-table-td" scope="col">
                  Проверок
                </th>
                <th className="users-table-td" scope="col">
                  Подписка
                </th>
                <th className="users-table-td" scope="col">
                  Подписка (до)
                </th>
                <th className="" scope="col">
                  ID
                </th>
              </tr>
            </thead>
            <tbody>
              <RenderUsers users={users} />
            </tbody>
          </table>
        ) : null}

        {usersNav(nav, clickOnPage)}
      </div>
    )
  }

  return null
}

export { Users }
