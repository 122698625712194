import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

// import { reloadPage } from '../../plugins/reload-page'

const postYMoney = (baseUrl, formdata, signalToken) => {
    const buySubscription = new Promise(function(resolve, reject) {
        const url = `${baseUrl}/api/plans/buy/`
        axios.post(url, formdata, {
            cancelToken: signalToken
        })
        .then((res) => {
            if (res?.status !== 200) throw new Error()
            resolve(res)
        })
        .catch((e) => {  
            // if (e?.response?.status === 401) {
            //     reloadPage(e)
            //     return
            // }

            const errorMessage = <>Ошибка сервера, попробуйте повторить <Link to="/payment">выбор тарифа.</Link></>
            reject({errorMessage: errorMessage, serverMessage: e?.message})
        })
    })
    return buySubscription
}

export { postYMoney }