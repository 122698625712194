import React from 'react'
import axios from 'axios'

import { reloadPage } from '../../../plugins/reload-page'

const getUsers = (baseUrl, signalToken) => {
  const users = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/users`

    axios
      .get(url, {
        cancelToken: signalToken,
        headers: { Authorization: `Bearer ${localStorage?.getItem('access')}` },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        const errorMessage = <>Ошибка сервера, обновляем данные...</>
        reject({ errorMessage: errorMessage })
      })
  })
  return users
}

export { getUsers }
