import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { AlertMessage } from '../../../../../plugins/alert-message'

class PriceFileComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            file: '',
            message: '',
            disabled: true
        }
    }

    onDismiss = () => {
        this.setState({
            message: ''
        })
    }

    handleFile = (e) => {
        const files = e?.target?.files[0]
        const splitName = files?.name?.split('.')
        const lastValueArr = splitName?.length - 1
        const fileName = files?.name?.split('.')[lastValueArr]

        if (fileName === 'csv' || fileName === 'xlsx' || fileName === 'xls' || fileName === 'xml' || fileName === 'yml') {
            this.setState({
                file: files,
                disabled: '',
                message: ''
            })
        } else {
            this.setState({
                file: '',
                disabled: true,
                message: 'Загружен некорректный формат, загрузите прайс в формате: XLSX, XLS, CSV, XML, YML.'
            })
        }
    }

    handleSubmitFile = () => {
        if (this.state?.file?.name?.length > 0) {
            this.props.history.push({
                pathname: '/campaign/price/column-matching',
                state: {
                    file: this.state.file,
                    id_campaign: this?.props?.match?.params?.id || null
                }
            })
        }
    }

    render() { 
        return (
            <>
                <div className="content-wrapper">
                    <div className="cardHeader">
                        2. Загрузка прайса файлом
                    </div>

                    <AlertMessage message={ this.state.message } onDismiss={ this.onDismiss } variant="danger" />

                    <div className="card rounded-0">
                        <div className="card-body">
                            <input
                                type="file"
                                onChange={ this.handleFile }
                            />
                        </div>
                        <div className="bg-info cardFooter rounded-0">
                            <button
                                className={ this.state.disabled ? 'btnDisabled btn btn-light' : 'btn btn-light' }
                                variant="info"
                                type="submit"
                                onClick={ this.handleSubmitFile }
                                value="Отправить">Отправить
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const PriceFile = withRouter(PriceFileComponent)
export { PriceFile }
