import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import './payment.css'

const MethodRadiosComponent = ({data, YMoney, baseUrlApp, baseNameApp, history}) => {

    const didmount = useRef(true)

    const [input1, setInput1] = useState(true)
    const [input2, setInput2] = useState(false)
    const [input3, setInput3] = useState(false)

    useEffect(() => {
        return (() => {
            didmount.current = false
        })
    }, [])

    const onValueChange = (input) => {
        if (input === 'input1') {
            setInput1(true)
            setInput2(false)
            setInput3(false)
            return
        }

        if (input === 'input2') {
            setInput1(false)
            setInput2(true)
            setInput3(false)
            return  
        }

        if (input === 'input3') {
            setInput1(false)
            setInput2(false)
            setInput3(true)
            return  
        }
    }

    const formSubmit = (e) => {
        e.preventDefault()

        if (input3) {
            history.push({
                pathname: '/payment/cashless-payment',
                state: {
                    'minСostCashlessPayment': data?.minСostCashlessPayment
                }
            })
            return
        }

        history.push({
            pathname: '/payment/method/redirect',
            state: {
                'receiver': YMoney,
                'formcomment': `Оплата тарифа ${baseNameApp}`,
                'short-dest': `Оплата тарифа ${baseNameApp}`,
                'label': `{"user_id": ${data?.user_id}, "plan_id": ${data?.plan_id}, "project": "PRICE"}`,
                'quickpay-form': 'shop',
                'targets': data?.targets,
                'sum': data?.sum,
                'successURL': `${baseUrlApp}/payment/success-pay`,
                'paymentType': input1 ? 'AC' : 'PC'
            }
        })
    }

    return (
        <form onSubmit={(e) => formSubmit(e)} className="payment-methodRadios-form">
            <div className="form-check payment-methodRadios-form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={input1}
                    onChange={() => onValueChange('input1')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Банковская карта
                </label>
            </div>
            <div className="form-check payment-methodRadios-form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={input2}
                    onChange={() => onValueChange('input2')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                    ЮMoney (Яндекс.Деньги)
                </label>
            </div>
            <div className="form-check payment-methodRadios-form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                    checked={input3}
                    onChange={() => onValueChange('input3')}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                    Безналичный расчет
                </label>
            </div>

            <button type="submit" className="btn btn-link btn-none-shadow">Перейти к оплате</button>
        </form>
    )
}
const MethodRadios = withRouter(MethodRadiosComponent)
export { MethodRadios }