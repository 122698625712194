// константы с именем типа actions
const ACTIVE_CAMPAIGNS = 'ACTIVE_CAMPAIGNS'
const ACTIVE_CAMPAIGNS_CLEAR = 'ACTIVE_CAMPAIGNS_CLEAR'
const ACTIVE_CAMPAIGNS_FILTER = 'ACTIVE_CAMPAIGNS_FILTER'
const ACTIVE_CAMPAIGNS_NAVIGATION = 'ACTIVE_CAMPAIGNS_NAVIGATION'
const ACTIVE_CAMPAIGNS_SEARCH_INPUT_TEXT = 'ACTIVE_CAMPAIGNS_SEARCH_INPUT_TEXT'
const ACTIVE_CAMPAIGNS_MESSAGE = 'ACTIVE_CAMPAIGNS_MESSAGE'

// дефолтное состояние редюсера
const defaultState = {
  activeCampaigns: null,
  activeCampaignsFilter: null,
  activeCampaignsSearchInputText: '',
  activeCampaignsMessage: null,
  activeCampaignsNavigation: {
    itemsPerPage: 3,
    maxPages: null,
    currentPage: 1,
    previousPage: false,
    nextPage: false,
  },
}

// ACTION-creator, функция которая принимает данные:
// {id: "1", userid: 1, email: "some@email.com"}
// и возвращает объект с типом экшена и данными переданным через параметр:
// { type: SET_USER, payload: {id: "1", userid: 1, email: "some@email.com"} }
export const actionActiveCampaigns = (campaigns = defaultState?.activeCampaigns) => {
  return {
    type: ACTIVE_CAMPAIGNS,
    payload: campaigns, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

export const actionActiveCampaignsFilter = (campaigns = defaultState?.activeCampaignsFilter) => {
  return {
    type: ACTIVE_CAMPAIGNS_FILTER,
    payload: campaigns, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

export const actionActiveCampaignsNavigation = (navigation = defaultState?.activeCampaignsNavigation) => {
  return {
    type: ACTIVE_CAMPAIGNS_NAVIGATION,
    payload: navigation, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

export const actionActiveCampaignsSearchInputText = (text = defaultState?.activeCampaignsSearchInputText) => {
  return {
    type: ACTIVE_CAMPAIGNS_SEARCH_INPUT_TEXT,
    payload: text, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

export const actionActiveCampaignsMessage = (text = defaultState?.activeCampaignsMessage) => {
  return {
    type: ACTIVE_CAMPAIGNS_MESSAGE,
    payload: text, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

export const actionActiveCampaignsClear = (state = defaultState) => {
  return {
    type: ACTIVE_CAMPAIGNS_CLEAR,
    payload: state, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

// создание и экспорт редюсера   // состояние            // экшен
export default function campaignsReducer(state = defaultState, action) {
  // в зависимости от экшена выполняем действие
  switch (action.type) {
    case ACTIVE_CAMPAIGNS:
      return {
        ...state, // возвращаем текущее состояние и изменяем одно поле
        activeCampaigns: action.payload,
      }

    case ACTIVE_CAMPAIGNS_FILTER:
      return {
        ...state, // возвращаем текущее состояние и изменяем одно поле
        activeCampaignsFilter: action.payload,
      }

    case ACTIVE_CAMPAIGNS_NAVIGATION:
      return {
        ...state, // возвращаем текущее состояние и изменяем одно поле
        activeCampaignsNavigation: action.payload,
      }

    case ACTIVE_CAMPAIGNS_SEARCH_INPUT_TEXT:
      return {
        ...state, // возвращаем текущее состояние и изменяем одно поле
        activeCampaignsSearchInputText: action.payload,
      }

    case ACTIVE_CAMPAIGNS_MESSAGE:
      return {
        ...state, // возвращаем текущее состояние и изменяем одно поле
        activeCampaignsMessage: action.payload,
      }

    case ACTIVE_CAMPAIGNS_CLEAR:
      return {
        ...state,
        activeCampaigns: null,
        activeCampaignsFilter: null,
        activeCampaignsSearchInputText: '',
        activeCampaignsMessage: null,
        activeCampaignsNavigation: {
          itemsPerPage: 3,
          maxPages: null,
          currentPage: 1,
          previousPage: false,
          nextPage: false,
        },
      }

    // свитч который возвращает дефолтное состояние (обязательно)
    default:
      return state
  }
}
