import axios from 'axios'
import { Link } from 'react-router-dom'
import { reloadPage } from '../../plugins/reload-page'

const get10StringsPrice = (baseUrl, price_id, signalToken) => {
  const get10StringsPrice = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/price/${price_id}/preview`
    axios
      .get(url, {
        cancelToken: signalToken,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        reject({
          errorMessage: (
            <>
              Ошибка загрузки прайса, перейти в <Link to="/campaigns">мои кампании.</Link>
            </>
          ),
        })
      })
  })
  return get10StringsPrice
}

export { get10StringsPrice }
