const statusReport = (status) => {
  if (status === 'Collecting') {
    return (
      <div style={{ display: 'inline-block' }}>
        <div className="spinner-border spinner-border-sm text-info" role="status"></div>
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>Подготовка...</div>
      </div>
    )
  }

  if (status === 'Search') {
    return (
      <div style={{ display: 'inline-block' }}>
        <div className="spinner-border spinner-border-sm text-info" role="status"></div>
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>Поиск...</div>
      </div>
    )
  }

  if (status === 'Parsing') {
    return (
      <div style={{ display: 'inline-block' }}>
        <div className="spinner-border spinner-border-sm text-info" role="status"></div>
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>Парсинг...</div>
      </div>
    )
  }

  if (status === 'Report Waiting') {
    return (
      <div style={{ display: 'inline-block' }}>
        <div className="spinner-border spinner-border-sm text-info" role="status"></div>
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>Ожидание генерации</div>
      </div>
    )
  }

  if (status === 'Report Generation') {
    return (
      <div style={{ display: 'inline-block' }}>
        <div className="spinner-border spinner-border-sm text-info" role="status"></div>
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>Генерация файла</div>
      </div>
    )
  }

  if (status === 'Finished') {
    return <div>Завершен</div>
  }

  if (status === 'Error') {
    return <div>Ошибка</div>
  }

  return <div>---</div>
}

export { statusReport }
