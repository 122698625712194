import axios from 'axios'
import { reloadPage } from '../../plugins/reload-page'

const getCampaigns = (baseUrl, signalToken) => {
  const getCampaigns = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/campaigns`

    axios
      .get(url, {
        cancelToken: signalToken,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        const errorMessage = <>Ошибка сервера, обновляем данные...</>
        reject({ errorMessage: errorMessage })
      })
  })
  return getCampaigns
}

export { getCampaigns }
