import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

import { AlertMessage } from '../../../../plugins/alert-message'
import { createTask } from '../../../../services/create-task'
import './6-campaign-setup-completed.css'

class SetupCompletedComponent extends React.Component {
    constructor() {
        super()

        this.state = {
            message: null
        }
    }

    componentDidMount() {
        this.signal = axios.CancelToken.source()
        this.start = true
    }

    componentWillUnmount() {
        this.signal.cancel()
        this.start = false
    }

    onDismiss = () => {
        this.setState({
            message: null
        })
    }

    btnCreateTask = () => {
        createTask(this?.props?.baseUrl, this?.props?.match?.params?.id, this?.signal?.token)
        .then(() => {
            if (this?.start) {
                this.props.history.push({
                    pathname: `/campaign/${this?.props?.match?.params?.id}/reports`
                })
            }
        })
        .catch((e) => {
            if (this?.start) {
                this.setState({
                    message: e?.errorMessage
                })
            }
        })
    }

    render () {
        return (
            <>
                <div className="content-wrapper">
                    <div className="cardHeader">
                        5. Настройка кампании завершена.
                    </div>
                
                    <div className="custom-card-setup-max-with">
                        <AlertMessage message={ this.state.message } onDismiss={ this.onDismiss } variant="danger" />
                    </div>
                    
                    <div className="card rounded-0 custom-card-setup-max-with">
                        <div className="card-body">
                            <div className="d-grid gap-2">

                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: `/campaign/${this?.props?.match?.params?.id}/settings`
                                        })
                                    }}>Настройки
                                </button>

                                <button
                                    className="btn btn-success custom-btn-setup-start"
                                    type="button"
                                    onClick={() => {
                                        this.btnCreateTask()
                                    }}>Создать отчет
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const setupCompleted = withRouter(SetupCompletedComponent)
export { setupCompleted }