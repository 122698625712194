const verboxSendUser = (user, email) => {
  const script = document.createElement('script')
  script.text = `
        function verboxUser(username, email) {
          Verbox('setClientInfo', {name: username, email: email})
        }
        verboxUser('${user}', '${email}')
      `
  document.head.appendChild(script)
}

export { verboxSendUser }
