import React from 'react'

import { serverTime } from '../../../plugins/server-time'
import { reportCompletedIn } from '../../../plugins/report-completed-time'
import { statusReport } from '../../../plugins/status-report'
import { downloadLink } from '../../../plugins/download-link'

const RenderReports = ({data}) => {
    if (!data) {
        return null
    }

    const reports = data?.results

    const rows = reports?.map((item) => {
        return (
            <tr key={ item?.id }>
                <td className="reports-table-td">{ item?.campaign_id }</td>
                <td className="reports-table-td">{ item?.id }</td>
                <td className="reports-some-td reports-table-td">{ serverTime(item?.created_at) }</td>
                <td className="reports-some-td reports-table-td">{ item?.status === 'Finished' ? serverTime(item?.completed_at) : '-' }</td>
                <td className="reports-some-td reports-table-td">
                    {
                        item.status === 'Finished' ?
                            reportCompletedIn(item?.completed_at, item?.created_at)
                        : '-'
                    }
                </td>
                <td className="reports-table-td">{ item?.price_rows_count }</td>
                <td className="reports-table-td"> { statusReport(item?.status) } </td>
                <td className="">
                    { 
                        item?.status === 'Finished' && item?.reports[0]?.status === 'Error' ? 'Ошибка' :
                        (item?.status === 'Finished') ? 
                            <span
                                type="button" 
                                className="reports-card-block-price-btn-download"
                                onClick= { () => downloadLink(item?.reports[0]?.file) }
                                >Скачать
                            </span>
                        : '-'
                    }
                </td>
            </tr>
        )
    })  
    return rows
}

export { RenderReports }