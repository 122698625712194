import React from 'react'
import axios from 'axios'
import { reloadPage } from '../../plugins/reload-page'

const postDemoCampaign = (url, cancelToken) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `${url}/api/demo-campaign`,
        {},
        {
          cancelToken: cancelToken,
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('access')}`,
            'X-User': localStorage.getItem('selectedUserId') || null,
          },
        }
      )
      .then((res) => {
        if (res?.status === 201) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        const errorMessage = <>Ошибка сервера, обновите страницу</>
        reject({ errorMessage: errorMessage })
      })
  })
}

export { postDemoCampaign }
