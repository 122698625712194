import { verboxSendUser } from '../plugins/verbox-chat/send-user'

// константы с именем типа actions
const SET_USER = 'SET_USER'
const LOGOUT = 'SET_LOGOUT'
const SELECTED_USER = 'SELECTED_USER'

// дефолтное состояние редюсера
const defaultState = {
  currentUser: {},
  isAuth: false,
  selectedUser: null,
}

// ACTION-creator, функция которая принимает данные:
// {id: "1", userid: 1, email: "some@email.com"}
// и возвращает объект с типом экшена и данными переданным через параметр:
// { type: SET_USER, payload: {id: "1", userid: 1, email: "some@email.com"} }
export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user, //  полезные данные (payload), такие как id пользователя, его роль и т.д.
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
  }
}

export const actionSelectedUser = (user_id = defaultState?.selectedUser) => {
  return {
    type: SELECTED_USER,
    payload: user_id,
  }
}

// REDUCER
// пример входящего ACTION:
// { type: SET_USER, payload: {id: "1", userid: 1, email: "some@email.com"} }
// создание и экспорт редюсера   // состояние            // экшен
export default function userReducer(state = defaultState, action) {
  // в зависимости от экшена выполняем действие
  switch (action.type) {
    case SET_USER:
      verboxSendUser(action.payload.username, action.payload.email)

      return {
        ...state, // возвращаем текущее состояние и изменяем два поля
        currentUser: action.payload,
        isAuth: true,
      }
    case LOGOUT:
      // удаляем токен из локального хранилища
      localStorage.clear()

      return {
        ...state, // возвращаем текущее состояние и изменяем два поля
        currentUser: {},
        isAuth: false,
        selectedUser: null,
      }

    case SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      }

    // свитч который возвращает дефолтное состояние (обязательно)
    default:
      return state
  }
}
