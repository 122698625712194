import React, { useState, useEffect, useRef } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { resetPassword } from '../../../services/reset-password/reset-password'

import { AlertMessage } from '../../../plugins/alert-message'

import axios from 'axios'

const ResetPassword = (props) => {
  // проверяем авторизирован пользователь или нет
  const isAuth = useSelector((state) => {
    return state.user.isAuth
  })

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('danger')
  const cancelRequest = useRef(null)

  useEffect(() => {
    if (cancelRequest.current == null) {
      cancelRequest.current = axios.CancelToken.source()
    }

    return () => {
      cancelRequest.current.cancel()
    }
  }, [])

  const postRequest = (e) => {
    if (email.length > 0) {
      e.preventDefault()

      const formData = new FormData()
      formData.append('email', email)

      resetPassword(props.baseUrl, formData, cancelRequest.current?.token)
        .then(() => {
          setAlertVariant('success')
          setEmail('')
          setMessage('Письмо со ссылкой для сброса пароля, отправлено на ваш email.')
        })
        .catch((e) => {
          setAlertVariant('danger')
          setMessage(e?.errorMessage)
        })
    } else return
  }

  const onDismiss = () => {
    setMessage('')
  }

  if (!isAuth) {
    return (
      <>
        <Form>
          <div className="authheader">Сброс пароля</div>

          <AlertMessage message={message} onDismiss={onDismiss} variant={alertVariant} />

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Введите email</Form.Label>
            <Form.Control
              className="rounded-0"
              type="email"
              placeholder="email"
              required
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
          </Form.Group>

          <Button
            className="rounded-0"
            variant="info"
            type="submit"
            onClick={(e) => {
              postRequest(e)
            }}
          >
            Сбросить пароль
          </Button>
        </Form>
      </>
    )
  } else return <Redirect to="/" />
}

export { ResetPassword }
