import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { newPassword } from '../../../services/reset-password/new-password'

import { AlertMessage } from '../../../plugins/alert-message'

import axios from 'axios'

const NewPassword = (props) => {
  // проверяем авторизирован пользователь или нет
  const isAuth = useSelector((state) => {
    return state.user.isAuth
  })

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('danger')
  const cancelRequest = useRef(null)

  useEffect(() => {
    if (cancelRequest.current == null) {
      cancelRequest.current = axios.CancelToken.source()
    }

    return () => {
      cancelRequest.current.cancel()
    }
  }, [])

  const postRequest = () => {
    const formData = new FormData()
    formData.append('uid', props?.match?.params?.uid)
    formData.append('token', props?.match?.params?.token)
    formData.append('new_password', password)

    newPassword(props.baseUrl, formData, cancelRequest.current?.token)
      .then(() => {
        setAlertVariant('success')
        setMessage(
          <>
            Пароль успешно изменен, вход в кабинет по <Link to="/signin">ссылке.</Link>
          </>
        )
        setPassword('')
        setConfirmPassword('')
      })
      .catch((e) => {
        setAlertVariant('danger')
        setMessage(e?.errorMessage)
        setPassword('')
        setConfirmPassword('')
      })
  }

  const onDismiss = () => {
    setMessage('')
  }

  const checkPasswords = (e) => {
    if (password.length > 5 && confirmPassword.length > 5) {
      e.preventDefault()

      if (password === confirmPassword) {
        onDismiss()
        postRequest()
      } else {
        setAlertVariant('danger')
        setMessage('Введенье пароли не совпадают')
        setPassword('')
        setConfirmPassword('')
      }
    } else return
  }

  if (!isAuth) {
    return (
      <>
        <Form>
          <div className="authheader">Введите новый пароль</div>

          <AlertMessage message={message} onDismiss={onDismiss} variant={alertVariant} />

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Новый пароль</Form.Label>
            <Form.Control
              className="rounded-0"
              type="password"
              placeholder="password"
              required
              minLength="6"
              maxLength="100"
              value={password}
              onChange={(e) => setPassword(e?.target?.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword2">
            <Form.Label>Повторите пароль</Form.Label>
            <Form.Control
              className="rounded-0"
              type="password"
              placeholder="password"
              required
              minLength="6"
              maxLength="100"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e?.target?.value)}
            />
          </Form.Group>

          <Button
            className="rounded-0"
            variant="info"
            type="submit"
            onClick={(e) => {
              checkPasswords(e)
            }}
          >
            Сохранить
          </Button>
        </Form>
      </>
    )
  } else return <Redirect to="/" />
}

export { NewPassword }
