import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import { getActivePlan } from '../../services/getActivePlan/getActivePlan'
import { serverTime } from '../../plugins/server-time'

const Subscription = ({ baseUrl }) => {
  const cancelRequest = useRef(null)
  const loading = useRef(true)
  const didmount = useRef(true)

  const [plan, setPlan] = useState()
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (cancelRequest.current == null) {
      cancelRequest.current = axios.CancelToken.source()
    }

    function getData() {
      getActivePlan(baseUrl, cancelRequest.current?.token)
        .then((res) => {
          if (didmount.current) {
            loading.current = false

            setErrorMessage()
            setPlan(res?.data)
          }
        })
        .catch((e) => {
          if (didmount.current) {
            loading.current = false

            setErrorMessage(e?.errorMessage)
          }
        })
    }
    getData()

    const timerId = setInterval(getData, 1000)

    return () => {
      loading.current = false
      didmount.current = false

      clearInterval(timerId)

      cancelRequest.current.cancel()
    }
  }, [baseUrl])

  if (loading.current) {
    return null
  } else if (!loading.current && errorMessage) {
    return (
      <div className="content-wrapper-message">
        <div className="cardHeader">
          <h3>{errorMessage}</h3>
        </div>
      </div>
    )
  } else if (!loading.current && !errorMessage) {
    return (
      <div className="content-wrapper">
        <div className="cardHeader long-name-campaign-settings">Информация о текущей подписке</div>
        <div className="mb-3">Подписка: {plan?.plan_name ? 'активна' : 'не активна'}</div>

        {plan?.plan_name ? (
          <>
            <div className="mb-3">Выбранный тариф: {plan?.plan_name ? plan?.plan_name : '--'}</div>
            <div className="mb-3">Завершение: {plan?.plan_end_date ? serverTime(plan?.plan_end_date) : '--'}</div>
            <div className="mb-3">Проверок на сегодня: {new Intl.NumberFormat('ru-RU').format(plan?.units)}</div>
          </>
        ) : (
          <div className="mb-3">
            <Link to="/payment">Перейти к выбору подписки</Link>
          </div>
        )}
      </div>
    )
  }
}

export { Subscription }
