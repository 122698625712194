import React from 'react'
import { Link } from 'react-router-dom'

const pageNotFound = () => {

    return (
        <div className="content-wrapper-message">
            <div className="cardHeader">
                <h3>Страница не найдена, перейти на <Link to="/">главную.</Link></h3>
            </div>
        </div>
    )
}

export { pageNotFound }