import React from 'react'
import { withRouter, Link } from 'react-router-dom'

import { downloadLink } from '../../../../plugins/download-link'
import { checkAutoStartIsEnabled } from '../../../../plugins/check-auto-start-is-enabled'
import { autoStartDaysToRus } from '../../../../plugins/auto-start-days-to-rus'

class CardBlockComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      main: true,
      price: false,
      autoStart: false,
    }
  }

  componentDidMount() {
    this.start = true

    this.sidenavBlock = document.getElementsByClassName('sidenav')

    this.sidenavBlockMinHeight = Number(window.getComputedStyle(this.sidenavBlock[0]).minHeight.split('px')[0])
    this.startBlockHeight = Number(
      getComputedStyle(document.getElementsByClassName('campaign-card-all-blocks')[0]).height.split('px')[0]
    )

    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    this.start = false

    if (this?.sidenavBlock[0]?.style[0] === 'min-height') {
      this.sidenavBlock[0].style.removeProperty('min-height')
    }

    window.removeEventListener('resize', this.resize)
  }

  componentDidUpdate() {
    const width = document.documentElement.clientWidth

    if (width > 991) {
      this.sidenavBlockCss()
    }
  }

  activeTab = (tab) => {
    if (tab) {
      return 'nav-link active campaign-card-block-nav-link'
    } else return 'nav-link btn btn-link campaign-card-block-nav-link'
  }

  activeTabBlock = (block) => {
    if (!block) {
      return 'card-block-off'
    } else return ''
  }

  priceStatus = (status, price_id, id_campaign) => {
    if (status === 'Update') {
      return <div>Обновляем информацию...</div>
    }

    if (status === 'File Upload') {
      return <div>Загружается...</div>
    }

    if (status === 'Collecting') {
      return <div>Обработка...</div>
    }

    if (status === 'Column Matching') {
      return (
        <div>
          <div style={{ display: 'inline-block' }}>Ожидает задания колонок,</div>
          <div
            type="button"
            className="campaign-card-block-price-btn-match-columns"
            onClick={() =>
              this.props.history.push({
                pathname: '/campaign/price/column-matching',
                state: {
                  price_id: price_id,
                  id_campaign: id_campaign,
                },
              })
            }
          >
            <div style={{ display: 'inline-block', marginLeft: '5px' }}>задать колонки</div>
          </div>
        </div>
      )
    }

    if (status === 'Finished') {
      return <div>Загружен</div>
    }

    if (status === 'Wrong File Format') {
      return <div>Загружена ссылка на сайт, вместо прайса</div>
    }

    if (status === 'Error') {
      return <div>Ошибка</div>
    }
  }

  indicatorCss = (status) => {
    if (status === 'File Upload') {
      return 'bi bi-circle-fill indicator indicator-file-upload'
    }

    if (status === 'Collecting') {
      return 'bi bi-circle-fill indicator indicator-collecting'
    }

    if (status === 'Column Matching') {
      return 'bi bi-circle-fill indicator indicator-column-matching'
    }

    if (status === 'Finished') {
      return 'bi bi-circle-fill indicator indicator-finished'
    }

    if (status === 'Error') {
      return 'bi bi-circle-fill indicator indicator-error'
    }

    if (status === 'Wrong File Format') {
      return 'bi bi-circle-fill indicator indicator-error'
    }

    return 'bi bi-circle-fill indicator'
  }

  indicatorAutoStartCss = () => {
    if (checkAutoStartIsEnabled(this?.props?.data.auto_start, 'is_enabled')) {
      return 'bi bi-circle-fill indicator indicator-finished'
    } else {
      return 'bi bi-circle-fill indicator'
    }
  }

  btnDownloadLink = (link) => {
    const linkCheck = link.search(/^(http|https):\/\//)

    if (linkCheck !== -1) {
      downloadLink(this?.props?.data?.price_download)
    } else {
      downloadLink(this?.props?.baseUrl + this?.props?.data?.price_download)
    }
  }

  renderAutoStart = (data, item, campaign_id) => {
    const checkData = checkAutoStartIsEnabled(data, item)

    if (!checkData) {
      return (
        <div className="campaign-card-block-name">
          <div style={{ display: 'inline-block' }}>Расписание не активно, перейти в</div>
          <div
            type="button"
            className="campaign-card-block-price-btn-match-columns"
            onClick={() =>
              this.props.history.push({
                pathname: `/campaign/${campaign_id}/settings`,
                hash: '#planner',
              })
            }
          >
            <div style={{ display: 'inline-block', marginLeft: '5px' }}>настройки</div>
          </div>
        </div>
      )
    }

    if (checkData) {
      let arr = []
      let arr2 = []
      for (let key in data) {
        if (data[key]['is_enabled']) {
          arr.push(key)
          arr2.push([...data[key].times])
        }
      }

      return arr.map((item, index) => {
        return (
          <div key={item}>
            {index === 0 ? (
              <div className="campaign-card-block-name">
                <div style={{ display: 'inline-block' }}>Расписание активно, перейти в</div>
                <div
                  type="button"
                  className="campaign-card-block-price-btn-match-columns"
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/campaign/${campaign_id}/settings`,
                      hash: '#planner',
                    })
                  }
                >
                  <div style={{ display: 'inline-block', marginLeft: '5px' }}>настройки</div>
                </div>
              </div>
            ) : null}

            <div style={{ display: 'flex' }}>
              <div className="campaign-card-block-other-row campaign-card-block-days">
                {autoStartDaysToRus(item) + ': '}
              </div>
              <div className="campaign-card-block-times">
                {arr2[index].map((item, index, arr) => {
                  const comma = arr.length !== index + 1 ? ',' : ''
                  return ' ' + item + comma
                })}
              </div>
            </div>
          </div>
        )
      })
    }
  }

  tabClick = (item) => {
    if (item === 'autoStart') {
      this.setState({
        main: false,
        price: false,
        autoStart: !this.state.autoStart,
      })
    }

    if (item === 'price') {
      this.setState({
        main: false,
        price: !this.state.price,
        autoStart: false,
      })
    }

    if (item === 'main') {
      this.setState({
        main: !this.state.main,
        price: false,
        autoStart: false,
      })
    }
  }

  cardBlock = () => {
    return (
      <>
        <div className="card text-center campaign-card rounded-0">
          <div className="card-header campaign-card-header campaign-card-header-radius">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item text-selection-none">
                <span
                  className={this.activeTab(this.state.main)}
                  onClick={() => (this.state.main ? null : this.tabClick('main'))}
                >
                  Кампания
                </span>
              </li>
              <li className="nav-item text-selection-none">
                <span
                  className={this.activeTab(this.state.price)}
                  onClick={() => (this.state.price ? null : this.tabClick('price'))}
                >
                  Прайc <i className={this.indicatorCss(this?.props?.data?.price_status)} />
                </span>
              </li>
              <li className="nav-item text-selection-none">
                <span
                  className={this.activeTab(this.state.autoStart)}
                  onClick={() => (this.state.autoStart ? null : this.tabClick('autoStart'))}
                >
                  Расписание <i className={this.indicatorAutoStartCss()} />
                </span>
              </li>
              <li className="nav-item campaign-card-block-navId">
                <span>ID: {this?.props?.data?.id}</span>
              </li>
              <li className="nav-item campaign-card-block-btn-delete btn-delete-campaign">
                <button
                  type="button"
                  className="btn btn-danger custom-btn-campaign-del"
                  onClick={() => this?.props?.activeModal(true, this?.props?.data?.id, 'delete campaign')}
                >
                  <span style={{ fontSize: '10px' }}>X</span>
                </button>
              </li>
            </ul>
          </div>
          <div className={`card-body campaign-card-block-main ${this.activeTabBlock(this.state.main)}`}>
            <div className="campaign-card-block-name long-name">{this?.props?.data?.name}</div>
            <div className="campaign-card-block-other-row">Последний отчет: {this?.props?.data?.last_report}</div>
            <div className="campaign-card-block-other-row">
              Отчетов: <Link to={`/campaign/${this?.props?.data?.id}/reports`}>{this?.props?.data?.count_reports}</Link>
            </div>
            <div className="campaign-btn-links">
              <span>
                <Link to={`/campaign/${this?.props?.data?.id}/settings`}>Настройки</Link>
              </span>
              <span
                type="button"
                className="campaign-card-block-price-btn-create-task"
                onClick={() => {
                  this?.props?.activeModal(true, this?.props?.data?.id, 'create report')
                }}
              >
                Создать отчет
              </span>
            </div>
          </div>

          <div className={`card-body campaign-card-block-price ${this.activeTabBlock(this.state.price)}`}>
            <div className="campaign-card-block-price-name">
              {this.priceStatus(this?.props?.data?.price_status, this?.props?.data?.price_id, this?.props?.data?.id)}
            </div>
            <div className="campaign-card-block-other-row">Обновлен: {this?.props?.data?.last_price_update}</div>
            <div className="campaign-card-block-other-row">Товаров: {this?.props?.data?.price_rows_count}</div>
            <div className="campaign-btn-links">
              <span
                type="button"
                className="campaign-card-block-price-btn-download"
                onClick={() => this.btnDownloadLink(this?.props?.data?.price_download)}
              >
                Скачать
              </span>
              <span className="campaign-card-block-price-btn-upload">
                <Link to={`/campaign/${this?.props?.data?.id}/price-format`}>Загрузить</Link>
              </span>
            </div>
          </div>

          <div className={`card-body campaign-card-block-autoStart ${this.activeTabBlock(this.state.autoStart)}`}>
            {this.renderAutoStart(this?.props?.data?.auto_start, 'is_enabled', this?.props?.data?.id)}
          </div>
        </div>
      </>
    )
  }

  sidenavBlockCss = () => {
    const actualBlockHeight = Number(
      getComputedStyle(document.getElementsByClassName('campaign-card-all-blocks')[0]).height.split('px')[0]
    )

    const newBlockHeight = actualBlockHeight - this.startBlockHeight + this.sidenavBlockMinHeight
    this.sidenavBlock[0].style['min-height'] = newBlockHeight + 'px'
  }

  resize = () => {
    const width = document.documentElement.clientWidth

    if (width < 992) {
      this.sidenavBlock[0].style.removeProperty('min-height')
      this.sidenavBlockMinHeight = 840
      this.startBlockHeight = 690
    }

    if (width > 991) {
      this.sidenavBlockMinHeight = 840
      this.startBlockHeight = 690
      this.sidenavBlockCss()
    }
  }

  render() {
    return <> {this.cardBlock()} </>
  }
}

const CardBlock = withRouter(CardBlockComponent)
export { CardBlock }
