import React, { useEffect, useRef, useState } from 'react'
import {Link, withRouter } from 'react-router-dom'

import { AlertMessage } from '../../plugins/alert-message'
import { MethodRadios } from './methodRadios'

const ComponentMethod = ({history, YMoney, baseUrlApp, baseNameApp}) => {

    const didmount = useRef(true)
    const loading = useRef(true)

    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState()

    useEffect(() => {
        if (history?.location?.state && didmount.current) {
            setData(history?.location?.state)
            loading.current = false

            window.history.replaceState(null, '')
        }

        return( () => {
            loading.current = false
            didmount.current = false
        })

    }, [history?.location?.state])

    const onDismiss = () => {
        setErrorMessage(null)
    }

    if (loading.current && history?.location?.state) {
        return null
    } else if (!loading.current && history?.location?.state) {
        return (
            <>
                <div className="content-wrapper">
                    <div className="cardHeader">Оплата подписки</div>
                    <div className="mb-3">Выберите метод оплаты:</div>
                    <AlertMessage message={errorMessage} onDismiss={onDismiss} variant="danger" />

                    <MethodRadios
                        data={data}
                        YMoney={YMoney}
                        baseUrlApp={baseUrlApp}
                        baseNameApp={baseNameApp}
                    />
                </div>
            </>
        )
    } else if (loading.current && !history?.location?.state) {
        return (
            <div className="content-wrapper-message">
                <div className="cardHeader">
                    <h3>Не выбрано количество дней подписки, <Link to="/payment">перейти к выбору</Link></h3>
                </div>
            </div>
        )
    }
}

const Method = withRouter(ComponentMethod)
export {Method}