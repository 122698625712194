import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'

import { login } from '../../../services/authorization/login'
import { AlertMessage } from '../../../plugins/alert-message'

const SignInComponent = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [regMessage, setRegMessage] = useState('')
  const [signal, setSignal] = useState(axios.CancelToken.source())

  const dispatch = useDispatch()

  // проверяем авторизирован пользователь или
  const isAuth = useSelector((state) => {
    return state.user.isAuth
  })

  useEffect(() => {
    if (props?.history?.location?.state?.reg) {
      setRegMessage(props?.history?.location?.state?.reg)
      window.history.replaceState(null, '')

      const a = document.createElement('script')
      a.src = '/scripts/google.tag.js'
      document.head.appendChild(a)
    }
  }, [props?.history?.location?.state?.reg])

  // для отмены запроса axios (очистка при размонтировании)
  useEffect(() => {
    return () => {
      setSignal(signal.cancel('Api signin is being canceled'))
    }
  }, [signal])

  // для очистки сообщений
  const onDismiss = () => {
    setMessage('')
    setRegMessage('')
  }

  if (!isAuth) {
    return (
      <>
        <Form>
          <div className="authheader">Вход</div>

          {regMessage?.length > 0 ? (
            <AlertMessage message={regMessage} onDismiss={onDismiss} variant="success" />
          ) : null}

          <AlertMessage message={message} onDismiss={onDismiss} variant="danger" />

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Введите email</Form.Label>
            <Form.Control
              onChange={(event) => setEmail(event?.target?.value)}
              value={email}
              type="email"
              name="email"
              placeholder="email"
              required
              maxLength="100"
              className="rounded-0"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Введите пароль</Form.Label>
            <Form.Control
              onChange={(event) => setPassword(event?.target?.value)}
              value={password}
              type="password"
              name="password"
              placeholder="password"
              required
              minLength="6"
              maxLength="100"
              className="rounded-0"
            />
          </Form.Group>
          <Button
            variant="info"
            type="submit"
            className="rounded-0"
            onClick={(e) => {
              if (email.length > 0 && password.length > 5) {
                e.preventDefault() // отключаем перезагрузку

                // вызываем асинхронный ACTION
                dispatch(login(email, password, props.baseUrl, signal?.token))
                  .then((res) => {
                    if (res?.status === 200) {
                      return
                    }
                    if (res?.status === 401) {
                      setMessage(res?.data?.detail)
                      setEmail('')
                      setPassword('')
                      return
                    } else {
                      setMessage('Произошла ошибка, попробуйте повторить вход.')
                      setEmail('')
                      setPassword('')
                    }
                  })
                  .catch(() => {
                    setMessage('Произошла ошибка, попробуйте повторить вход.')
                    setEmail('')
                    setPassword('')
                  })
              } else return
            }}
          >
            Вход
          </Button>

          <LinkContainer to="resetpassword">
            <Button variant="second">Забыли пароль?</Button>
          </LinkContainer>
        </Form>
      </>
    )
  } else return <Redirect to="/" />
}

export const SignIn = withRouter(SignInComponent)
