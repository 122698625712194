const checkAutoStartIsEnabled = (data, item) => {
  let counter = 0

  for (let key in data) {
    if (item === 'is_enabled') {
      if (data[key][item] === true) {
        counter = ++counter
      }
    }

    if (item === 'times') {
      if (data[key][item].length > 0) {
        counter = ++counter
      }
    }
  }

  const result = counter > 0 ? true : false
  return result
}

export { checkAutoStartIsEnabled }
