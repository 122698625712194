import React from 'react'
import './modal.css'

const Modal = ({state, activeModal, text, action}) => {
    if (state) {
        return (
            <div 
                className="modal"
                onClick={ () => activeModal(false) }
                >
                <div className="modal-dialog" onClick={ (e) => e.stopPropagation() }>
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h5 className="modal-title text-selection-none">{text['1']}</h5>
                        </div>
                        <div className="modal-body text-selection-none">
                            <p>{text['2']}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success modal-btn-success"
                                onClick={ () => {
                                    activeModal(false)
                                    action()
                                } }
                            >
                                {text['4']}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={ () => activeModal(false) }
                            >
                                {text['3']}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return null
}

export { Modal }