import React from 'react'

const Qitem = ({ item, priceEXCEL, priceXML }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed questions-btn-header"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-${item.id}`}
        >
          {item.title}
        </button>
      </h2>
      <div id={`flush-${item.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionBlock">
        <div className="accordion-body" style={{ maxWidth: 800, paddingTop: 0, textAlign: 'justify ' }}>
          <div dangerouslySetInnerHTML={{ __html: item.answer }} />

          {item.price_example && (
            <div style={{ paddingTop: 15 }}>
              <div style={{ paddingBottom: 5 }}>
                <span>1) прайс файлом - </span>
                <a href={priceEXCEL} target="_blank" rel="noreferrer">
                  скачать
                </a>
              </div>
              <div>
                <span>2) прайс по ссылке - </span>
                <a href={priceXML} target="_blank" rel="noreferrer">
                  скачать
                </a>
              </div>
            </div>
          )}

          {item.video_url && (
            <div className="questions-video">
              <div className="questions-video-item">
                <iframe
                  width="100%"
                  height="315px"
                  src={`${item.video_url}`}
                  title="video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { Qitem }
