const usersNav = (state, clickOnPage) => {
  return state.maxPages > 1 ? (
    <nav className="text-selection-none">
      <ul className="pagination pagination-flex-wrap">
        {/* кнопка назад */}
        {state.previousPage ? (
          <li className="page-item">
            <span
              type="button"
              className="page-link rounded-0"
              onClick={() => {
                clickOnPage(state.currentPage - 1)
              }}
            >
              <span>Назад</span>
            </span>
          </li>
        ) : null}

        {/* на первую страницу */}
        {state.currentPage !== 1 ? (
          <li className="page-item">
            <span
              type="button"
              className="page-link rounded-0"
              onClick={() => {
                clickOnPage(1)
              }}
            >
              {1}
            </span>
          </li>
        ) : null}

        {/* номер текущей страницы */}
        <li className="page-item">
          <span type="button" className="page-link rounded-0 reports-navigation-active-page">
            {state.currentPage}
          </span>
        </li>

        {/* номер следующей страницы после текущей */}
        {state.maxPages >= state.currentPage + 1 ? (
          <li className="page-item">
            <span
              type="button"
              className="page-link rounded-0"
              onClick={() => {
                clickOnPage(state.currentPage + 1)
              }}
            >
              {state.currentPage + 1}
            </span>
          </li>
        ) : null}

        {/* номер следующей страницы после текущей и текущей + 1 */}
        {state.maxPages >= state.currentPage + 2 ? (
          <li className="page-item">
            <span type="button" className="page-link rounded-0" onClick={() => clickOnPage(state.currentPage + 2)}>
              {state.currentPage + 2}
            </span>
          </li>
        ) : null}

        {/* кнопка вперед */}
        {state.nextPage ? (
          <li className="page-item">
            <span type="button" className="page-link rounded-0" onClick={() => clickOnPage(state.currentPage + 1)}>
              <span>Вперед</span>
            </span>
          </li>
        ) : null}
      </ul>
    </nav>
  ) : null
}

export { usersNav }
