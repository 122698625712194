import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'

import { Headers } from './3-column-matching-headers'
import { AlertMessage } from '../../../../plugins/alert-message'
import { reloadPage } from '../../../../plugins/reload-page'
import { get10StringsPrice } from '../../../../services/get-10-strings-price'
import { getMessageTg } from '../../../../services/get-message-tg'
import { actionActiveCampaigns } from '../../../../reducers/campaigns-reducer.js'

import './3-column-matching.css'

class ColumnMatchingComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      priceData: null,
      matching: [],
      message: null,
      messageUpload: 'Загружаем прайс',
      loading: true,
      skipRows: '0',
    }

    this.timeStart = null
    this.timeEnd = null
  }

  componentDidMount() {
    this.priceDataId = null
    this.start = true
    this.signal = axios.CancelToken.source()

    // console.log(this?.props?.history?.location?.state) // undefined
    if (this?.props?.history?.location?.state !== undefined && !this?.props?.history?.location?.state?.price_id) {
      this.notificationRender()
      this.firstMount() // ПЕРВОЕ МЕСТО ГДЕ ПРАВИМ
    }

    // переход на страницу задания колонок у прайса со страницы /campaigns
    if (this?.props?.history?.location?.state !== undefined && this?.props?.history?.location?.state?.price_id) {
      this.notificationRender() // ВТОРОЕ МЕСТО ГДЕ ПРАВИМ
      this.matchColumns(this?.props?.baseUrl, this?.props?.history?.location?.state?.price_id, this.signal.token)
    }
  }

  componentWillUnmount() {
    this.start = false
    clearInterval(this.timer)
    this.signal.cancel('Api signup is being canceled')
  }

  onDismiss = () => {
    this.setState({
      message: '',
    })
  }

  notificationRender = () => {
    this.timer = setInterval(() => {
      this.setState((state) => {
        if (state.messageUpload === 'Загружаем прайс...') {
          return { messageUpload: 'Загружаем прайс.' }
        } else {
          return { messageUpload: state.messageUpload + '.' }
        }
      })
    }, 600)
  }

  firstMount = () => {
    let formData
    if (this?.props?.location?.state?.url && !this?.props?.location?.state?.file) {
      formData = new FormData()
      formData.append('link', this?.props?.location?.state?.url)
    } else if (!this?.props?.location?.state?.url && this?.props?.location?.state?.file) {
      formData = new FormData()
      formData.append('file', this?.props?.location?.state?.file)
    }

    let url
    if (this?.props?.location?.state?.url && this?.props?.location?.state?.id_campaign) {
      url = `${this?.props?.baseUrl}/api/campaign/${this?.props?.location?.state?.id_campaign}/price/upload/link`
    } else if (this?.props?.location?.state?.url && !this?.props?.location?.state?.id_campaign) {
      url = `${this?.props?.baseUrl}/api/price/upload/link`
    } else if (this?.props?.location?.state?.file && this?.props?.location?.state?.id_campaign) {
      url = `${this.props.baseUrl}/api/campaign/${this?.props?.location?.state?.id_campaign}/price/upload`
    } else if (this?.props?.location?.state?.file && !this?.props?.location?.state?.id_campaign) {
      url = `${this.props.baseUrl}/api/price/upload`
    }

    this.timeStart = new Date()
    if (this.props?.consoleDebug) {
      console.log('1) Начата загрузка прайса на сервер')
    }

    // 1. отправка прайса на сервер
    axios
      .post(url, formData, {
        cancelToken: this.signal.token,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          if (this.start) {
            this.timeEnd = new Date()
            if (this.props?.consoleDebug) {
              const sec = ((this.timeStart.getTime() - this.timeEnd.getTime()) / (1000 * -1)).toFixed(1)
              const min = (((this.timeStart.getTime() - this.timeEnd.getTime()) / (1000 * -1)).toFixed(1) / 60).toFixed(
                2
              )
              console.log(`2) Завершена загрузка прайса за ${sec} sec (${min} min)`)
            }

            // 2. получили ID прайса на сервере
            if (this.props?.consoleDebug) {
              console.log(`3) Получили ID прайса: ${res?.data?.id ? res?.data?.id : res?.data?.price_id}`)
            }

            // изменяем статус у прайса, чтобы затереть предыдущий
            if (
              this?.props?.activeCampaigns &&
              this?.props?.activeCampaigns.length > 0 &&
              this?.props?.location?.state?.id_campaign
            ) {
              let newActiveCampaigns = JSON.parse(JSON.stringify(this?.props?.activeCampaigns))
              newActiveCampaigns.forEach((item) => {
                if (item.id === +this?.props?.location?.state?.id_campaign) {
                  item.price_status = 'Update'
                }
              })
              this?.props?.actionActiveCampaigns(newActiveCampaigns)
            }

            return res
          }
        } else throw new Error()
      })
      .then((res) => {
        if (res?.status === 200) {
          // останавливаем сообщение о загрузке прайса
          clearInterval(this.timer)

          this.props.history.push({
            pathname: '/campaign/price/column-upload',
            state: {
              priceId: res?.data?.price_id,
              campaign_id: res?.data?.campaign_id,
              skip_city_select: this?.props?.location?.state?.id_campaign ? true : false,
              url: this?.props?.location?.state?.url ? this?.props?.location?.state?.url : null,
            },
          })

          return
        }

        if (res?.status === 201) {
          // 3. получение первых 10 строк из прайса
          this.priceDataId = res?.data?.id

          const url = `${this?.props?.baseUrl}/api/price/${res?.data?.id}/preview`

          axios
            .get(url, {
              cancelToken: this.signal.token,
              headers: {
                Authorization: `Bearer ${localStorage?.getItem('access')}`,
                'X-User': localStorage.getItem('selectedUserId') || null,
              },
            })
            .then((res) => {
              if (res?.status !== 200) {
                throw new Error()
              }

              if (this.start && res?.data?.price?.length === 0) {
                if (this.props?.consoleDebug) {
                  console.log('4) Загружен пустой прайс')
                }

                // останавливаем сообщение о загрузке прайса
                clearInterval(this.timer)

                this.setState({
                  messageUpload: (
                    <>
                      Загружен прайс без товаров, перейти в <Link to="/campaigns">мои кампании.</Link>
                    </>
                  ),
                })
                return
              }

              if (this.start) {
                if (this.props?.consoleDebug) {
                  console.log('4) Получили первые 10 строк прайса')
                }

                // останавливаем сообщение о загрузке прайса
                clearInterval(this.timer)

                this.setState({
                  priceData: res?.data?.price,
                  loading: false,
                })
                return
              }
            })
            .catch((e) => {
              if (this.start && e?.response?.status === 401) {
                reloadPage(e)
                return
              }

              if (this.start) {
                clearInterval(this.timer)

                this.setState({
                  messageUpload: (
                    <>
                      Ошибка получения данных прайса, перейти в <Link to="/campaigns">мои кампании.</Link>
                    </>
                  ),
                })
              }
            })
        }
      })
      .catch((e) => {
        if (this.start && e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        if (this.start) {
          clearInterval(this.timer)

          this.setState({
            messageUpload: (
              <>
                Ошибка загрузки прайса, перейти в <Link to="/campaigns">мои кампании.</Link>
              </>
            ),
          })

          getMessageTg(
            // eslint-disable-next-line
            'Пользователь: ' + this.props.user + ', загрузил ПРАЙС файлом' + ' и получил ошибку обработки прайса.',
            this.signal.token
          ).catch((e) => {
            console.log('Ошибка отправки запроса в телеграм: ' + e?.errorMessage + '')
          })
        }
      })
    window.history.replaceState(null, '')
  }

  matchColumns = (baseUrl, price_id, signal) => {
    get10StringsPrice(baseUrl, price_id, signal)
      .then((res) => {
        if (this.start && res?.data?.price?.length === 0) {
          if (this.props?.consoleDebug) {
            console.log('4) Загружен пустой прайс')
          }

          // останавливаем сообщение о загрузке прайса
          clearInterval(this.timer)

          this.setState({
            messageUpload: (
              <>
                Загружен прайс без товаров, перейти в <Link to="/campaigns">мои кампании.</Link>
              </>
            ),
          })
          return
        }

        if (this.start && res?.data?.price?.length > 0) {
          if (this.props?.consoleDebug) {
            console.log('4) Получили первые 10 строк прайса')
          }

          // останавливаем сообщение о загрузке прайса
          clearInterval(this.timer)

          this.setState({
            priceData: res?.data?.price,
            loading: false,
          })
        }
      })
      .catch((e) => {
        if (this.start) {
          clearInterval(this.timer)

          this.setState({
            messageUpload: e?.errorMessage,
          })
        }
      })
    window.history.replaceState(null, '')
  }

  renderHeaders = () => {
    return this.state?.priceData[0]?.map((item, index) => {
      let css
      if (index === 0) {
        css = `border-top-off border-top`
      } else {
        css = `border-top-off border-top border-start`
      }

      return (
        <th className={css} key={index} scope="col">
          <Headers
            index={index}
            state={(id) => {
              this.setState((state) => {
                const arr = state.matching.filter((item) => {
                  return item.idHeader !== id.idHeader
                })

                if (id.idColumn === '0') {
                  return { matching: [...arr] }
                } else {
                  return { matching: [...arr, id] }
                }
              })
            }}
          />
        </th>
      )
    })
  }

  renderColumns = () => {
    return this.state.priceData.map((item, index) => {
      return (
        <tr key={index}>
          {item.map((i, index) => {
            let css
            if (index === 0) {
              css = `truncate-text`
            } else {
              css = `border-start truncate-text`
            }
            return (
              <td className={css} key={index}>
                {i}
              </td>
            )
          })}
        </tr>
      )
    })
  }

  countMatching = () => {
    if (this.state.matching.length === 0) {
      return 'btnDisabled btn btn-light'
    } else {
      return 'btn btn-light'
    }
  }

  buttonSend = () => {
    if (this?.state?.matching?.length === 0) {
      return
    } else {
      const arr = this?.state?.matching

      // проверка на выбор задания колонок
      let x = 0
      let y = 0
      let z = 0
      // let p = 0

      arr.forEach((item) => {
        if (item.idColumn === '1') {
          x++
        }
        if (item.idColumn === '2') {
          y++
        }
        if (item.idColumn === '3') {
          z++
        }
        // if (item.idColumn === '4') {
        //   p++
        // }
      })

      if (x === 0) {
        this.setState({
          message: 'Не выбрана колонка с названиями товаров.',
        })
        return
      } else if (x > 1) {
        this.setState({
          message: 'Возможно выбрать только одну колонку с названием.',
        })
        return
      }
      if (y > 1) {
        this.setState({
          message: 'Возможно выбрать только одну колонку с ценой товара.',
        })
        return
      }
      if (z > 1) {
        this.setState({
          message: 'Возможно выбрать только одну колонку с идентификатором.',
        })
        return
      }
      // if (p > 1) {
      //   this.setState({
      //     message: 'Возможно выбрать только одну колонку с ID или URL на товар.',
      //   })
      //   return
      // }

      // если колонки заданы верно, очистить сообщение
      this.setState({
        message: null,
      })

      // готовим объект к отправке
      let obj = {}
      obj.columns = this.state.matching
      obj.skipRows = this.state.skipRows || '0'

      let url
      // url для отправки объекта
      if (this?.props?.history?.location?.state?.price_id) {
        url = `${this?.props?.baseUrl}/api/price/${this?.props?.history?.location?.state?.price_id}/column-matching`
      } else {
        url = `${this?.props?.baseUrl}/api/price/${this?.priceDataId}/column-matching`
      }

      // отправляем сопоставленные колонки
      axios
        .post(url, obj, {
          cancelToken: this.signal.token,
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('access')}`,
            'X-User': localStorage.getItem('selectedUserId') || null,
          },
        })
        .then((res) => {
          if (res?.status !== 200) {
            throw new Error()
          }

          if (res?.status === 200 && res?.data?.message === 'Success' && this.start) {
            // изменяем статус у прайса, чтобы затереть предыдущий
            if (this?.props?.activeCampaigns && this?.props?.activeCampaigns.length > 0) {
              let newActiveCampaigns = JSON.parse(JSON.stringify(this?.props?.activeCampaigns))
              newActiveCampaigns.forEach((item) => {
                if (item.id === res?.data?.campaign_id) {
                  item.price_status = 'Update'
                }
              })
              this?.props?.actionActiveCampaigns(newActiveCampaigns)
            }

            if (this?.props?.activeCampaigns?.length === 0) {
              this?.props?.actionActiveCampaigns(null)
            }

            if (this.props?.consoleDebug) {
              console.log('5) Объект передан')
              console.log(obj)
              console.log(
                `5.1) Перенаправляем на страницу /campaign/price/column-upload\nи передаем:\nprice_id: ${
                  res?.data?.price_id
                }\ncampaign_id : ${res?.data?.campaign_id}\nskip_city_select: ${
                  this?.props?.location?.state?.id_campaign ? true : false
                }`
              )
            }

            this.props.history.push({
              pathname: '/campaign/price/column-upload',
              state: {
                priceId: res?.data?.price_id,
                campaign_id: res?.data?.campaign_id,
                skip_city_select: this?.props?.location?.state?.id_campaign ? true : false,
                url: this?.props?.location?.state?.url ? this?.props?.location?.state?.url : null,
              },
            })
          }
        })
        .catch((e) => {
          if (this.start && e?.response?.status === 401) {
            reloadPage(e)
            return
          }

          if (this.start) {
            this.setState({
              messageUpload: (
                <>
                  Ошибка соответствия колонок, перейти в <Link to="/campaigns">мои кампании.</Link>
                </>
              ),
              loading: true,
            })
          }
          window.history.replaceState(null, '')
        })
    }
  }

  skipRows = (num) => {
    const number = num?.replace(/[^\d]/g, '')

    this.setState({
      skipRows: number,
    })
  }

  render() {
    if (this?.props?.location?.state && this.state.loading) {
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            <h3>{this.state.messageUpload}</h3>
          </div>
        </div>
      )
    }

    if (this?.props?.location?.state === undefined) {
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            <h3>
              Прайс не загружен, перейти в <Link to="/campaigns">мои кампании.</Link>
            </h3>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="content-wrapper-column-matching">
          <div className="cardHeader">3. Задайте соответствия колонок</div>
          <AlertMessage message={this.state.message} onDismiss={this.onDismiss} variant="danger" />

          <div className="column-matching-skip-rows">
            Пропуск первых строк, задать количество:{' '}
            <input
              className="form-control form-control-sm column-matching-skip-rows-input"
              type="text"
              value={this.state.skipRows}
              onChange={(e) => this.skipRows(e?.target?.value)}
            />
          </div>

          <table className="table-css table-striped table margin-bottom border">
            <thead>
              <tr>{this.state.priceData && this.renderHeaders()}</tr>
            </thead>

            <tbody>{this.state.priceData && this.renderColumns()}</tbody>
          </table>

          <div className="bg-info cardFooter rounded-0 border">
            <button className={this.countMatching()} onClick={() => this.buttonSend()} value="Отправить">
              Отправить
            </button>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    activeCampaigns: store.userCampaigns.activeCampaigns,
    selectedUser: store.user.selectedUser,
    user: store.user.currentUser.email,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actionActiveCampaigns: (campaigns) => dispatch(actionActiveCampaigns(campaigns)),
  }
}

export const ColumnMatching = connect(mapStateToProps, mapDispatchToProps)(withRouter(ColumnMatchingComponent))
