import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { config } from './config'
import { SignIn } from './components/authorization/signin'
import { SignUp } from './components/authorization/signup'
import { ResetPassword } from './components/authorization/reset-password'
import { NewPassword } from './components/authorization/new-password'
import { auth } from './services/authorization/auth'
import { NavbarMenu } from './components/navbar'
import { HomePage } from './components/home-page'
import { priceFormat } from './components/campaign/campaign-create/1-price-format'
import { PriceFile } from './components/campaign/campaign-create/2-price-upload/price-file'
import { PriceUrl } from './components/campaign/campaign-create/2-price-upload/price-url'
import { ColumnMatching } from './components/campaign/campaign-create/3-column-matching'
import { ColumnUpload } from './components/campaign/campaign-create/4-column-upload'
import { RegionSelect } from './components/campaign/campaign-create/5-campaign-region-select'
import { setupCompleted } from './components/campaign/campaign-create/6-campaign-setup-completed'
import { pageNotFound } from './plugins/404'
import { Campaigns } from './components/campaign/campaigns-page'
import { CampaignReports } from './components/campaign/campaign-reports'
import { CampaignsReports } from './components/campaign/campaigns-reports'
import { CampaignSettings } from './components/campaign/campaign-settings'
import { Payment, Method, successPay, RedirectUrl, CashlessPayment } from './components/payment'
import { Subscription } from './components/subscription'
import { Users } from './components/admin/users'
import { Questions } from './components/questions'

import { checkFirstPageLoad } from './plugins/check-first-page-load'

function AppComponent() {
  const [status, setStatus] = useState()
  const dispatch = useDispatch()

  // проверяем авторизирован пользователь или нет
  const isAuth = useSelector((state) => {
    return state.user.isAuth
  })

  // проверяем админ или нет
  const isAuthAdmin = useSelector((state) => {
    return state.user.currentUser.is_staff
  })

  // проверяем админ зашел под клиентом или нет
  const isAuthAdminFromUser = useSelector((state) => {
    return state.user.selectedUser
  })

  // принимает первым параметром функцию, а вторым массив зависимостей
  // функция вызывается один раз, как компонент монтируется и после каждый раз как изменится зависимость
  useEffect(() => {
    // для отмены запроса axios
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    if (!isAuth) {
      dispatch(auth(config._baseUrl, source)).then((res) => {
        setStatus(res?.status)
      })
    }

    if (isAuth) {
      const time = 60 * 60000 // каждый час
      const timerId = setInterval(() => {
        // console.log('запуск таймера на обновление токена')
        dispatch(auth(config._baseUrl, source)).then((res) => {
          setStatus(res?.status)
        })
      }, time)

      return () => {
        // console.log('остановка таймера и отмена первого запроса')
        source.cancel()
        clearInterval(timerId)
      }
    }
  }, [dispatch, isAuth])

  const AuthRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuth) {
            return (
              <Component
                {...props}
                baseUrl={config._baseUrl}
                baseUrlApp={config._baseUrlApp}
                baseUrlLanding={config._baseUrlLanding}
                baseNameApp={config._baseNameApp}
                YMoney={config._YMoney}
                consoleDebug={config.consoleDebug}
                priceXML={config.priceXML}
                priceEXCEL={config.priceEXCEL}
              />
            )
          } else {
            checkFirstPageLoad(window.location)
            return <Redirect to='/signin' />
          }
        }}
      />
    )
  }

  const AuthRouteAdmin = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthAdmin && !isAuthAdminFromUser) {
            return (
              <Component
                {...props}
                baseUrl={config._baseUrl}
                baseUrlApp={config._baseUrlApp}
                baseUrlLanding={config._baseUrlLanding}
                baseNameApp={config._baseNameApp}
                YMoney={config._YMoney}
                consoleDebug={config.consoleDebug}
                priceXML={config.priceXML}
                priceEXCEL={config.priceEXCEL}
              />
            )
          }

          if (isAuthAdmin && isAuthAdminFromUser) {
            return <Redirect to='/campaigns' />
          }

          return <Redirect to='/signin' />
        }}
      />
    )
  }

  const WrappedResetPassword = (props) => <NewPassword {...props} baseUrl={config._baseUrl} />

  if (status) {
    return (
      <BrowserRouter>
        {/* маршрутизация по страницам */}
        <NavbarMenu baseUrlLanding={config._baseUrlLanding} baseNameApp={config._baseNameApp} />
        <div className='app'>
          {/*чтобы не потерялись стили */}
          <Switch>
            <Route exact path='/' render={() => <HomePage checkSignIn={status} />} />

            {/* // передать компонент */}
            <Route path='/signin' render={() => <SignIn baseUrl={config._baseUrl} />} />
            <Route path='/signup' render={() => <SignUp baseUrl={config._baseUrl} />} />
            <Route path='/resetpassword' render={() => <ResetPassword baseUrl={config._baseUrl} />} />
            <Route exact path='/reset_password/:uid/:token' component={WrappedResetPassword} />

            <AuthRoute exact path='/campaign/price-format' component={priceFormat} />
            <AuthRoute exact path='/campaign/:id/price-format' component={priceFormat} />
            <AuthRoute exact path='/campaign/price-file-upload' component={PriceFile} />
            <AuthRoute exact path='/campaign/:id/price-file-upload' component={PriceFile} />
            <AuthRoute exact path='/campaign/price-url-upload' component={PriceUrl} />
            <AuthRoute exact path='/campaign/:id/price-url-upload' component={PriceUrl} />
            <AuthRoute exact path='/campaign/price/column-matching' component={ColumnMatching} />
            <AuthRoute exact path='/campaign/price/column-upload' component={ColumnUpload} />
            <AuthRoute exact path='/campaign/:id/region-select' component={RegionSelect} />
            <AuthRoute exact path='/campaign/:id/settings/setup-completed' component={setupCompleted} />
            <AuthRoute exact path='/campaigns' component={Campaigns} />
            <AuthRoute exact path='/campaign/:id/reports' component={CampaignReports} />
            <AuthRoute exact path='/campaigns/reports' component={CampaignsReports} />
            <AuthRoute exact path='/campaign/:id/settings' component={CampaignSettings} />

            <AuthRoute exact path='/payment' component={Payment} />
            <AuthRoute exact path='/payment/cashless-payment' component={CashlessPayment} />
            <AuthRoute exact path='/payment/method' component={Method} />
            <AuthRoute exact path='/payment/method/redirect' component={RedirectUrl} />
            <AuthRoute exact path='/payment/success-pay' component={successPay} />

            <AuthRoute exact path='/subscription' component={Subscription} />
            <AuthRoute exact path='/questions' component={Questions} />

            <AuthRouteAdmin exact path='/users' component={Users} />

            {/* // вызвать компонент */}
            {/* <AuthRoute2 path='/signup'>
              <SignUp />
            </AuthRoute2> */}

            {/* // если ни один из путей не был найден (работает в связке со Switch ) */}
            <Route component={pageNotFound} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  } else {
    return null
  }
}

export const App = AppComponent
