import axios from 'axios'
import { setUser } from '../../reducers/user-reducer'

const auth = (baseUrl, source) => {
  return async (dispatch) => {
    // если токена нет, не делаем запрос на аутентификацию
    if (!localStorage?.getItem('refresh') || !localStorage?.getItem('access')) {
      localStorage.clear()
      return { status: 401 }
    }

    try {
      const url = `${baseUrl}/api/auth/jwt/refresh`
      const response = await axios
        .post(
          url,
          {
            refresh: localStorage?.getItem('refresh'),
          },
          {
            cancelToken: source.token,
          }
        )
        .then((res) => {
          // храним токен, полученный от сервера
          localStorage.setItem('access', res?.data?.access)
          return res
        })
        .then(async (res) => {
          const url = `${baseUrl}/api/auth/users/me/`
          const response = await axios.get(url, {
            cancelToken: source.token,
            headers: { Authorization: `Bearer ${res?.data?.access}` },
          })

          return response
        })
      // вызываем dispatch и передаем ACTION, где в параметрах полученный user
      // response.data.user = {id: "1", userid: 1, email: "some@email.com"}

      // ACTION вернет объект(экшен)
      // { type: SET_USER, payload: {id: "1", userid: 1, email: "some@email.com"} }

      // dispatch отправит экшен в reducer, где будет вызван case
      // где получим измененного пользователя и параметр отвечающий за авторизацию
      // { currentUser: 1, isAuth: true }

      // если вызвана отмена запроса вывести в catch
      if (source?.token?.reason === undefined) {
        dispatch(setUser(response?.data))

        return response
      } else {
        throw new Error()
      }
    } catch (e) {
      // console.log(`AXIOS, отменен: ${axios.isCancel(e)}`)
      localStorage.clear()
      return { status: 401 }
    }
  }
}

export { auth }
