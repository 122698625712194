import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../reducers/user-reducer'
import { withRouter } from 'react-router-dom'
import {
  actionActiveCampaignsNavigation,
  actionActiveCampaignsSearchInputText,
  actionActiveCampaignsMessage,
  actionActiveCampaignsFilter,
  actionActiveCampaignsClear,
} from '../../reducers/campaigns-reducer'

import { actionAdminUsersSearchInputText } from '../../reducers/admin-reducer'
import { actionSelectedUser } from '../../reducers/user-reducer'

import './navbar.css'

import { matchPath } from 'react-router-dom'
const isActive = (path, location) => {
  const opts = {
    path, // /peoples'
    exact: true,
    // exact: path === '/', // false
  }
  return !!matchPath(location, opts)
}

const NavbarComponent = (props) => {
  const dispatch = useDispatch()

  // redux
  const redux = useSelector((state) => {
    return {
      isAuth: state.user.isAuth,
      isStaff: state.user.currentUser.is_staff,
      selectedUser: state.user.selectedUser,
      activeCampaignsNavigation: state.userCampaigns.activeCampaignsNavigation,
      activeCampaigns: state.userCampaigns.activeCampaigns,
    }
  })

  useEffect(() => {
    function checkSelectedUser() {
      const selectedUserEmail = localStorage.getItem('selectedUserEmail')
      const selectedUserId = localStorage.getItem('selectedUserId')

      if (selectedUserEmail && selectedUserId && selectedUserEmail !== redux?.selectedUser?.email) {
        dispatch(actionSelectedUser({ id: selectedUserId, email: selectedUserEmail }))
      }

      if (!selectedUserEmail && !selectedUserId && redux.selectedUser) {
        dispatch(actionSelectedUser())
      }
    }
    checkSelectedUser()

    window.addEventListener('storage', checkSelectedUser)

    return () => {
      window.removeEventListener('storage', checkSelectedUser)
    }
  }, [dispatch, redux.selectedUser])

  const pushToCampaigns = () => {
    if (!isActive('/campaigns', props.location.pathname)) return

    if (isActive('/campaigns', props.location.pathname)) {
      dispatch(
        actionActiveCampaignsNavigation({
          ...redux.activeCampaignsNavigation,
          maxPages: Math.ceil(redux.activeCampaigns?.length / redux.activeCampaignsNavigation?.itemsPerPage),
          nextPage:
            Math.ceil(redux.activeCampaigns?.length / redux.activeCampaignsNavigation?.itemsPerPage) > 1 ? true : false,
          currentPage: 1,
          previousPage: false,
        })
      )
      dispatch(actionActiveCampaignsSearchInputText(''))
      dispatch(actionActiveCampaignsMessage())
      dispatch(actionActiveCampaignsFilter())
    }
  }

  const clearAdminUsersSearchInputText = () => {
    dispatch(actionAdminUsersSearchInputText(''))
  }

  const closeMobileMenu = () => {
    const x = document?.querySelector('#navbarSupportedContent')
    const z = document?.querySelector('#root > nav > div > button')

    if (x?.className === 'navbar-collapse collapse show') {
      x.className = 'navbar-collapse collapse'
    }

    if (z?.className === 'navbar-toggler') {
      z.className = 'navbar-toggler collapsed'
      z.setAttribute('aria-expanded', 'false')
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          {!redux.isAuth ? (
            <a href={props?.baseUrlLanding}>
              <span className="navbar-brand">{props?.baseNameApp}</span>
            </a>
          ) : (
            <span
              className="navbar-brand"
              onClick={() => {
                closeMobileMenu()

                if (redux.isStaff) {
                  clearAdminUsersSearchInputText()

                  props.history.push({
                    pathname: '/users',
                  })
                } else {
                  props.history.push({
                    pathname: '/campaigns',
                  })
                }
              }}
            >
              {props?.baseNameApp}
            </span>
          )}

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {!redux.isAuth ? null : (
              <div className="navbar-nav me-auto mb-2 mb-lg-0 sidenav">
                {redux.isStaff && !redux.selectedUser ? (
                  <div
                    className={isActive('/users', props.location.pathname) ? 'menuActiveColor' : ''}
                    onClick={() => {
                      closeMobileMenu()
                      pushToCampaigns()
                      clearAdminUsersSearchInputText()

                      props.history.push({
                        pathname: '/users',
                      })
                    }}
                  >
                    <button
                      type="button"
                      className={isActive('/users', props.location.pathname) ? 'btnMenuActive grow' : 'btnMenu grow'}
                    >
                      Пользователи
                    </button>
                  </div>
                ) : null}

                <div
                  className={isActive('/campaigns', props.location.pathname) ? 'menuActiveColor' : ''}
                  onClick={() => {
                    closeMobileMenu()
                    pushToCampaigns()

                    props.history.push({
                      pathname: '/campaigns',
                    })
                  }}
                >
                  <button
                    type="button"
                    className={isActive('/campaigns', props.location.pathname) ? 'btnMenuActive grow' : 'btnMenu grow'}
                  >
                    Мои кампании
                  </button>
                </div>

                <div
                  className={isActive('/campaigns/reports', props.location.pathname) ? 'menuActiveColor' : ''}
                  onClick={() => {
                    closeMobileMenu()

                    props.history.push({
                      pathname: '/campaigns/reports',
                    })
                  }}
                >
                  <button
                    type="button"
                    className={
                      isActive('/campaigns/reports', props.location.pathname) ? 'btnMenuActive grow' : 'btnMenu grow'
                    }
                  >
                    Мои отчеты
                  </button>
                </div>

                <div
                  className={isActive('/subscription', props.location.pathname) ? 'menuActiveColor' : ''}
                  onClick={() => {
                    closeMobileMenu()

                    props.history.push({
                      pathname: '/subscription',
                    })
                  }}
                >
                  <button
                    type="button"
                    className={
                      isActive('/subscription', props.location.pathname) ? 'btnMenuActive grow' : 'btnMenu grow'
                    }
                  >
                    Моя подписка
                  </button>
                </div>

                <div
                  className={isActive('/questions', props.location.pathname) ? 'menuActiveColor' : ''}
                  onClick={() => {
                    closeMobileMenu()

                    props.history.push({
                      pathname: '/questions',
                    })
                  }}
                >
                  <button
                    type="button"
                    className={isActive('/questions', props.location.pathname) ? 'btnMenuActive grow' : 'btnMenu grow'}
                  >
                    Вопросы
                  </button>
                </div>
              </div>
            )}

            {redux.isAuth ? null : (
              <div className="navbar-nav nav-btn-right">
                <div>
                  <button
                    className={
                      isActive('/signin', props.location.pathname)
                        ? 'btnMenuActive btnMenu-top-right'
                        : 'btnMenu btnMenu-top-right'
                    }
                    onClick={() => {
                      closeMobileMenu()

                      props.history.push({
                        pathname: '/signin',
                      })
                    }}
                  >
                    Вход
                  </button>
                </div>

                <div>
                  <button
                    className={
                      isActive('/signup', props.location.pathname)
                        ? 'btnMenuActive btnMenu-top-right'
                        : 'btnMenu btnMenu-top-right'
                    }
                    onClick={() => {
                      closeMobileMenu()

                      props.history.push({
                        pathname: '/signup',
                      })
                    }}
                  >
                    Регистрация
                  </button>
                </div>
              </div>
            )}

            {!redux.isAuth ? null : (
              <div className="navbar-nav nav-btn-right">
                {redux.selectedUser ? (
                  <div className="navbar-div-logout-selected-user">
                    <span style={{ color: 'white' }}>Кабинет </span>
                    <span style={{ color: '#91ffaa' }}>{redux.selectedUser.email}</span>
                    <span style={{ color: 'white' }}>, </span>
                    <span
                      className="navbar-btn-logout-selected-user"
                      onClick={() => {
                        localStorage.removeItem('selectedUserEmail')
                        localStorage.removeItem('selectedUserId')

                        closeMobileMenu()

                        dispatch(actionSelectedUser())
                        dispatch(actionActiveCampaignsClear())

                        props.history.push({
                          pathname: '/users',
                        })
                      }}
                    >
                      покинуть
                    </span>
                  </div>
                ) : null}

                <div>
                  <button
                    type="button"
                    className={
                      isActive('/payment', props.location.pathname)
                        ? 'btnMenuActive btnMenu-top-right'
                        : 'btnMenu btnMenu-top-right'
                    }
                    onClick={() => {
                      closeMobileMenu()

                      props.history.push({
                        pathname: '/payment',
                      })
                    }}
                  >
                    Купить
                  </button>
                </div>

                <div>
                  <button
                    className="btnMenu btnMenu-top-right"
                    onClick={() => {
                      closeMobileMenu()
                      clearAdminUsersSearchInputText()

                      dispatch(logout())
                      dispatch(actionActiveCampaignsClear())
                    }}
                  >
                    Выйти
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

const NavbarMenu = withRouter(NavbarComponent)

export { NavbarMenu }
