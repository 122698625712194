import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { reloadPage } from '../../../../plugins/reload-page'
import { getMessageTg } from '../../../../services/get-message-tg'

class ColumnUploadComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      messageUpload: 'Обработка прайса',
      loading: true,
      uploadSiteUrl: false,
    }
    this.timeStart = null
    this.timeEnd = null
  }

  componentDidMount() {
    // отмена запросов
    this.signal = axios.CancelToken.source()
    this.start = true

    if (this?.props?.location?.state !== undefined) {
      this.timer = setInterval(() => {
        this.setState((state) => {
          if (state.messageUpload === 'Обработка прайса...') {
            return { messageUpload: 'Обработка прайса.' }
          } else {
            return { messageUpload: state.messageUpload + '.' }
          }
        })
        // console.log(this.state.messageUpload)
      }, 600)
      this.firstMount()
    }
  }

  componentWillUnmount() {
    // отмена запросов
    this.signal.cancel('Api signup is being canceled')
    this.start = false
    clearInterval(this.timer)
  }

  firstMount() {
    this.timeStart = new Date()
    if (this.props?.consoleDebug) {
      console.log('6) Начата обработка прайса сервером')
    }

    const get = () => {
      const url = `${this.props.baseUrl}/api/price/${this?.props?.history?.location?.state?.priceId}`
      axios
        .get(url, {
          cancelToken: this.signal.token,
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('access')}`,
            'X-User': localStorage.getItem('selectedUserId') || null,
          },
        })
        .then((res) => {
          if (res?.status !== 200) {
            throw new Error()
          }
          if (res?.data?.price?.status === 'Error' && this.start) {
            clearInterval(this.timer)
            this.setState({
              messageUpload: (
                <>
                  Ошибка обработки прайса, перейти в <Link to="/campaigns">мои кампании.</Link>
                </>
              ),
            })
            return
          }
          if (res?.data?.price?.status === 'Finished' && this.start) {
            this.timeEnd = new Date()
            if (this.props?.consoleDebug) {
              const sec = ((this.timeStart.getTime() - this.timeEnd.getTime()) / (1000 * -1)).toFixed(1)
              const min = ((this.timeStart.getTime() - this.timeEnd.getTime()) / (1000 * -1).toFixed(1) / 60).toFixed(2)
              console.log(`7) Завершена обработка прайса за ${sec} sec (${min} min)`)
            }

            clearInterval(this.timer)

            this.setState({
              messageUpload: 'Прайс обработан успешно!',
            })

            if (this.props?.consoleDebug && !this?.props?.history?.location?.state?.skip_city_select) {
              console.log(
                `8) Перенаправляем на страницу /campaign/${this?.props?.history?.location?.state?.campaign_id}/region-select`
              )
            }

            // если прайс был загружен повторно в кампанию, пропускаем шаг задания городов
            if (this?.props?.history?.location?.state?.skip_city_select) {
              if (this.props?.consoleDebug) {
                console.log(
                  `8) Перенаправляем на страницу /campaign/${this?.props?.history?.location?.state?.campaign_id}/settings/setup-completed`
                )
              }

              this.props.history.push({
                pathname: `/campaign/${this?.props?.history?.location?.state?.campaign_id}/settings/setup-completed`,
              })
              return
            }

            this.props.history.push({
              pathname: `/campaign/${this?.props?.history?.location?.state?.campaign_id}/region-select`,
            })

            return
          }
          if (res?.data?.price?.status === 'Wrong File Format') {
            clearInterval(this.timer)
            this.setState({
              messageUpload: (
                <>
                  <h4 style={{ marginBottom: 15 }}>Загружен некорректный формат прайса</h4>
                  <span style={{ fontSize: 16, marginBottom: 10, display: 'block' }}>
                    Необходимо загрузить корректный прайс, примеры:
                  </span>
                  <span style={{ fontSize: 16, marginBottom: 5, display: 'block' }}>
                    <span>1) прайс файлом - </span>
                    <a href="https://pricehelper.ru/my_price.xlsx" target="_blank" rel="noreferrer">
                      скачать
                    </a>
                  </span>
                  <span style={{ fontSize: 16, marginBottom: 10, display: 'block' }}>
                    <span>2) прайс по ссылке - </span>
                    <a href="https://pricehelper.ru/my_price.xml" target="_blank" rel="noreferrer">
                      скачать
                    </a>
                  </span>
                  <span style={{ fontSize: 16 }}>
                    <span>Загрузить прайс повторно </span>
                    <Link to={`/campaign/${this.props.location?.state?.campaign_id}/price-format`}>перейти</Link>
                  </span>
                </>
              ),
              uploadSiteUrl: true,
            })
            getMessageTg(
              'Пользователь: ' +
                this.props.userEmail +
                ', загрузил ссылку на сайт: ' +
                `${this.props.location?.state?.url}` +
                ' и получил пример с прайсами.',
              this.signal.token
            ).catch((e) => {
              console.log('Ошибка отправки запроса в телеграм: ' + e?.errorMessage + '')
            })
            return
          }
          if (res?.data?.price?.status !== 'Finished' && this.start) {
            setTimeout(get, 600)
          }
        })
        .catch((e) => {
          if (this.start && e?.response?.status === 401) {
            reloadPage(e)
            return
          }
          if (this.start) {
            this.setState({
              messageUpload: (
                <>
                  Ошибка обработки прайса, перейти в <Link to="/campaigns">мои кампании.</Link>
                </>
              ),
              uploadSiteUrl: false,
            })
          }
          clearInterval(this.timer)
        })
    }
    get()

    // очищаем полученный ID прайса
    window.history.replaceState(null, '')
  }

  render() {
    if (this?.props?.history?.location?.state?.priceId && this.state.loading) {
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            {this.state.uploadSiteUrl ? this.state.messageUpload : <h3>{this.state.messageUpload}</h3>}
          </div>
        </div>
      )
    }

    return (
      <div className="content-wrapper-message">
        <div className="cardHeader">
          <h3>
            Прайс не загружен, перейти в <Link to="/campaigns">мои кампании.</Link>
          </h3>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    userEmail: store.user.currentUser.email,
  }
}

export const ColumnUpload = connect(mapStateToProps)(withRouter(ColumnUploadComponent))
