import axios from 'axios'

const getMessageTg = (message, signalToken) => {
  const getMessageTg = new Promise(function (resolve, reject) {
    const token = '1946617621:AAFY4kpW61D1-_hmSeiy6JvUuJzlK5bRGQA'
    const chatId = '-579644675'
    const url = 'https://api.telegram.org/bot' + token + '/sendMessage?chat_id=' + chatId + '&text=' + message + ''

    axios
      .get(url, {
        cancelToken: signalToken ? signalToken : null,
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        const errorMessage = e?.message
        reject({ errorMessage: errorMessage })
      })
  })
  return getMessageTg
}

export { getMessageTg }
