import React from 'react'

import { hours, minutes } from './timeData'

function Times(props) {
  const times = props.autoStart[props.day].times

  const setAutoStart = (day, index, time, format) => {
    props.setAutoStart((prevState) => {
      let newObj = JSON.parse(JSON.stringify(prevState))

      for (let key in newObj) {
        if (key === day) {
          const data = newObj[key].times[index].split(':')

          if (format === 'hour') {
            newObj[key].times[index] = time + ':' + data[1]
          }

          if (format === 'minute') {
            newObj[key].times[index] = data[0] + ':' + time
          }
        }
      }

      return {
        ...newObj,
      }
    })
  }

  const renderTimeDropdown = (index, format) => {
    if (format === 'hour') {
      const data = hours.map((hour) => {
        return (
          <li key={hour + index}>
            <button
              id={hour + ':h'}
              className="dropdown-item auto-start-dropdown-menu-btn"
              onClick={() => {
                setAutoStart(props.day, index, hour, 'hour')
              }}
            >
              {hour}
            </button>
          </li>
        )
      })
      return data
    }

    if (format === 'minute') {
      const data = minutes.map((minute) => {
        return (
          <li key={minute + index}>
            <button
              id={minute + ':m'}
              className="dropdown-item auto-start-dropdown-menu-btn"
              onClick={() => setAutoStart(props.day, index, minute, 'minute')}
            >
              {minute}
            </button>
          </li>
        )
      })
      return data
    }
  }

  const addTime = (day) => {
    props.setAutoStart((prevState) => {
      let newObj = JSON.parse(JSON.stringify(prevState))

      for (let key in newObj) {
        if (key === day) {
          if (newObj[day]['times'].length === 0) {
            newObj[day]['is_enabled'] = true
          }

          newObj[day].times.push('00:00')
        }
      }

      return {
        ...newObj,
      }
    })
  }

  const delTime = (day, index) => {
    props.setAutoStart((prevState) => {
      let newObj = JSON.parse(JSON.stringify(prevState))

      for (let key in newObj) {
        if (key === day) {
          newObj[day]['times'].splice(index, 1)
        }
      }

      if (newObj[day]['times'].length === 0) {
        newObj[day]['is_enabled'] = false
      }

      return {
        ...newObj,
      }
    })
  }

  const renderTimes = () => {
    const data = times.map((item, index, arr) => {
      const time = item.split(':')
      const hour = time[0]
      const minute = time[1]

      return (
        <div key={item + index} className="auto-start-check-box">
          <div className="dropdown-auto-start-check-box">
            <button
              className="dropdown-auto-start-btn btn btn-info dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {hour}
            </button>
            <ul className="dropdown-menu auto-start-dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {renderTimeDropdown(index, 'hour')}
            </ul>
          </div>
          :
          <div className="dropdown-auto-start-check-box">
            <button
              className="dropdown-auto-start-btn btn btn-info dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {minute}
            </button>
            <ul className="dropdown-menu auto-start-dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {renderTimeDropdown(index, 'minute')}
            </ul>
          </div>
          <button type="button" className="btn btn-danger auto-start-btn-del" onClick={() => delTime(props.day, index)}>
            <i className="bi bi-x-lg auto-start-btn-del-svg"></i>
          </button>
          {arr.length === index + 1 ? (
            <button type="button" className="btn btn-success auto-start-btn-add" onClick={() => addTime(props.day)}>
              <i className="bi bi-plus-lg auto-start-btn-add-svg"></i>
            </button>
          ) : null}
        </div>
      )
    })
    return data
  }

  return renderTimes()
}

export { Times }
