// корневой редюсер, объединяет все редюсеры
import { applyMiddleware, combineReducers, createStore } from 'redux'
// среда разработки в браузере
import { composeWithDevTools } from 'redux-devtools-extension'
// middleware, решает задачу асинхронности
import thunk from 'redux-thunk'
// import logger from 'redux-logger'

//импорт созданных редюсеров
import userReducer from './user-reducer'
import adminReducer from './admin-reducer'
import campaignsReducer from './campaigns-reducer'

// присваиваем кореневой редюсер в переменную
const rootReducer = combineReducers({
  // перечисляем созданные редюсеры
  user: userReducer,
  admin: adminReducer,
  userCampaigns: campaignsReducer,
})

// создаем и экспортируем store               среда разработки в браузере
// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)))
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
