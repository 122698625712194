import axios from 'axios'
import { reloadPage } from '../../plugins/reload-page'

const patchCampaignSettings = (baseUrl, campaignId, body, signalToken) => {
  const patchCampaignSettings = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/campaign/${campaignId}`

    axios
      .patch(url, body, {
        cancelToken: signalToken,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve()
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        const errorMessage = 'Ошибка сервера, попробуйте повторить действие.'
        reject({ errorMessage: errorMessage })
      })
  })
  return patchCampaignSettings
}

export { patchCampaignSettings }
