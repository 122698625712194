import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'

import { postYMoney } from '../../services/post-y-money'
import { getMessageTg } from '../../services/get-message-tg/getMessageTg'

const RedirectUrl = (props) => {

    const loading = useRef(true)
    const cancelRequest = useRef(null)
    const [errorMessage, setErrorMessage] = useState()

    const redux = useSelector(
        (state) => {
            return {
                email: state?.user?.currentUser?.email
            }
        }
    )

    useEffect(() => {
        if (cancelRequest.current == null) {
            cancelRequest.current = axios.CancelToken.source()
        }

        if (props?.location?.state) {
            let formdata = new FormData()

            formdata.append('receiver', props?.location?.state?.receiver)
            formdata.append('formcomment', props?.location?.state?.formcomment)
            formdata.append('short-dest', props?.location?.state['short-dest'])
            formdata.append('label', props?.location?.state?.label)
            formdata.append('quickpay-form', props?.location?.state['quickpay-form'])
            formdata.append('targets', props?.location?.state?.targets)
            formdata.append('sum', props?.location?.state?.sum)
            formdata.append('successURL', props?.location?.state?.successURL)
            formdata.append('paymentType', props?.location?.state?.paymentType)

            window.history.replaceState(null, '')
    
            postYMoney(props?.baseUrl, formdata, cancelRequest.current?.token)
            .then((res) => {
                if (loading.current) {
                    document.location.assign(res?.data?.message)
                }
            })
            .catch((e) => {           
                if (loading.current) {
                    setErrorMessage(e?.errorMessage)

                    getMessageTg(
                        'Ошибка получения ссылки для редиректа на оплату, ответ сервера: '+e?.serverMessage+'. Email клиента: '+redux?.email+'',
                        cancelRequest.current?.token
                    )
                    .catch((e) => {
                        console.log('Ошибка отправки запроса в телеграм: '+e?.errorMessage+'')
                    })
                }
            })
        }

        return (() => {
            cancelRequest.current.cancel()
            loading.current = false
        })
    }, [props?.baseUrl, props?.location?.state, redux?.email])

    if (props?.location?.state && loading.current && !errorMessage) {
        return (
            <div className="content-wrapper-message">
                <div className="cardHeader">
                    <h3>Перенаправляем...</h3>
                </div>
            </div>
        )
    } else if (!props?.location?.state && loading.current && !errorMessage) {
        return (
            <div className="content-wrapper-message">
                <div className="cardHeader">
                    <h3>Не выбрано количество дней подписки, <Link to="/payment">перейти к выбору</Link></h3>
                </div>
            </div>
        )
    } else {
        return (
        <div className="content-wrapper-message">
            <div className="cardHeader">
                <h3>{ errorMessage }</h3>
            </div>
        </div>
        )
    }
}

export { RedirectUrl }