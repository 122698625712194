import React from 'react'
import { Alert } from 'react-bootstrap';

class AlertMessage extends React.Component {
    constructor() {
        super()

        this.state = ({
            message: ''
        })
    }

    componentDidMount() {
        this.setState({
            message: this?.props?.message
        })
    }

    componentDidUpdate(prevProps) {       
        if (this?.props?.message !== prevProps?.message) {
          this.setState({
            message: this?.props?.message
          })
        }
    }

    render() {
        return (
            (this?.state?.message?.length > 0 || this?.state?.message?.props?.children?.length > 0) ? (
                <Alert className="rounded-0" variant={ this?.props?.variant } onClose={() => this?.props?.onDismiss() } dismissible>
                    { this.state.message }
                </Alert>
            ) : null
        )
    }
}
export { AlertMessage }