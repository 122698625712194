const downloadLink = (url) => {
    const link = document.createElement('a')
    link.id = 'downloadFile'
    link.href = url
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    document.getElementById('downloadFile').remove()
}

export { downloadLink }