import React from 'react'

const CardHeader = ({ tabs, setTabs, campaignId }) => {
  const activeTab = (tab) => {
    if (tab) {
      return 'nav-link active campaign-card-block-nav-link'
    } else return 'nav-link btn btn-link campaign-card-block-nav-link'
  }

  return (
    <div className="card-header campaign-settings-card-header">
      <ul className="nav nav-tabs card-header-tabs">
        <li className="nav-item text-selection-none">
          <span
            className={activeTab(tabs.main)}
            onClick={() =>
              tabs.main
                ? null
                : setTabs({
                    main: !tabs.main,
                    cities: false,
                    autoStart: false,
                  })
            }
          >
            Основные
          </span>
        </li>
        <li className="nav-item text-selection-none">
          <span
            className={activeTab(tabs.cities)}
            onClick={() =>
              tabs.cities
                ? null
                : setTabs({
                    main: false,
                    cities: !tabs.cities,
                    autoStart: false,
                  })
            }
          >
            Города
          </span>
        </li>
        <li className="nav-item text-selection-none">
          <span
            className={activeTab(tabs.autoStart)}
            onClick={() =>
              tabs.autoStart
                ? null
                : setTabs({
                    main: false,
                    cities: false,
                    autoStart: !tabs.autoStart,
                  })
            }
          >
            Расписание
          </span>
        </li>
        <li className="nav-item campaign-settings-card-block-navId">
          <span>ID: {campaignId}</span>
        </li>
      </ul>
    </div>
  )
}

export { CardHeader }
