const config = {
  _baseUrl: 'https://api.pricehelper.ru',
  _baseUrlApp: 'https://my.pricehelper.ru',
  _baseUrlLanding: 'https://pricehelper.ru',
  _baseNameApp: 'pricehelper.ru',
  _YMoney: '4100118168483750',
  consoleDebug: false,
  priceXML: 'https://pricehelper.ru/my_price.xml',
  priceEXCEL: 'https://pricehelper.ru/my_price.xlsx',
}

export { config }
