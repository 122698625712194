import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { registration } from '../../../services/authorization/registration'
import { withRouter } from 'react-router-dom'
import { AlertMessage } from '../../../plugins/alert-message'
import axios from 'axios'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { getMessageTg } from '../../../services/get-message-tg'

class SignUpComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      name: '',
      email: '',
      password: '',
      message: '',
    }
  }

  componentDidMount() {
    this.signal = axios.CancelToken.source()
    this.start = true
    this.startRequest = false
  }

  componentWillUnmount() {
    this.signal.cancel('Api signup is being canceled')
    this.start = false
  }

  sendErrorToTg = (e) => {
    getMessageTg(
      'Пользователь: ' +
        this.state.email +
        ', пробует пройти регистрацию' +
        ', но получает ошибку: ' +
        `${JSON.stringify(e)}`,
      null
    ).catch((e) => {
      console.log('Ошибка отправки запроса в телеграм: ' + e?.errorMessage + '')
    })
  }

  ChangeName = (event) => {
    this.setState({
      name: event?.target?.value,
    })
    this.startRequest = false
  }

  ChangeEmail = (event) => {
    this.setState({
      email: event?.target?.value,
    })
    this.startRequest = false
  }

  ChangePassword = (event) => {
    this.setState({
      password: event?.target?.value,
    })
    this.startRequest = false
  }

  Validation = async (e) => {
    if (this.startRequest) {
      e.preventDefault()
      return
    }

    if (this.state.name.length > 0 && this.state.email.length > 0 && this.state.password.length > 5) {
      e.preventDefault()
      this.startRequest = true
      registration(this.state.name, this.state.email, this.state.password, this.props.baseUrl, this.signal.token)
        .then((res) => {
          if (res.status === 201) {
            this.props.history.push({
              pathname: '/signin',
              state: { reg: 'Спасибо за регистрацию!' },
            })
            return
          }

          const usernameError = res?.data?.username && res?.data?.username[0]
          const passwordError = res?.data?.password && res?.data?.password[0]
          const emailError = res?.data?.email && res?.data?.email[0]
          const isErros = !!usernameError || !!passwordError || !!emailError

          if (isErros) {
            this.setState({
              message: usernameError || passwordError || emailError,
              name: '',
              email: '',
              password: '',
            })
          } else throw new Error(JSON.stringify(res))
        })
        .catch((e) => {
          if (this.start) {
            this.sendErrorToTg(e.message)

            this.setState({
              message: 'Произошла ошибка, попробуйте повторить регистрацию.',
              name: '',
              email: '',
              password: '',
            })
          }
        })
    }
  }

  onDismiss = () => {
    this.setState({
      message: '',
    })
    this.startRequest = false
  }

  render() {
    if (!this?.props?.user?.isAuth) {
      return (
        <>
          <Form>
            <div className="authheader">Регистрация</div>

            <AlertMessage message={this.state.message} onDismiss={this.onDismiss} variant="danger" />

            <Form.Group>
              <Form.Label>Как вас зовут?</Form.Label>
              <Form.Control
                onChange={this.ChangeName}
                value={this.state.name}
                type="text"
                name="name"
                placeholder="name"
                required
                maxLength="100"
                className="rounded-0"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Введите email</Form.Label>
              <Form.Control
                onChange={this.ChangeEmail}
                value={this.state.email}
                type="email"
                name="email"
                placeholder="email"
                required
                maxLength="100"
                className="rounded-0"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Введите пароль</Form.Label>
              <Form.Control
                onChange={this.ChangePassword}
                value={this.state.password}
                type="password"
                name="password"
                placeholder="password"
                required
                minLength="6"
                maxLength="100"
                className="rounded-0"
              />
            </Form.Group>
            <Button variant="info" type="submit" onClick={this.Validation} className="rounded-0">
              Регистрация
            </Button>
          </Form>
        </>
      )
    } else return <Redirect to="/" />
  }
}

function mapStateToProps(state) {
  return state
}

export const SignUp = connect(mapStateToProps)(withRouter(SignUpComponent))
