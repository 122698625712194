import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'

import { CardBlock } from './campaigns-page-card-block'
import { AlertMessage } from '../../../plugins/alert-message'
import { campaignNavigation } from './campaigns-page-navigation'
import { getCampaigns } from '../../../services/get-campaigns'
import { campaignName } from '../../../plugins/campaign-name'
import { deleteCampaign } from '../../../services/delete-campaign'
import { CampaignSelect } from './campaign-select'

import { Modal } from '../../../plugins/modal'
import { createTask } from '../../../services/create-task'

import {
  actionActiveCampaigns,
  actionActiveCampaignsFilter,
  actionActiveCampaignsNavigation,
  actionActiveCampaignsSearchInputText,
  actionActiveCampaignsMessage,
} from '../../../reducers/campaigns-reducer'

import './campaigns-page.css'

class CampaignsComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      errorMessage: null,
      campaignId: null,
      modal: false,
      modalText: null,
      actionFn: null,
    }
  }

  componentDidMount() {
    this.signal = axios.CancelToken.source()
    this.start = true
    document.addEventListener('keydown', this.escFunction, false)

    this.firstLoading = setInterval(() => {
      this.getCampaigns()
    }, 2000)
  }

  componentDidUpdate(prevProps) {
    if (this?.props?.activeCampaignsSearchInputText !== prevProps?.activeCampaignsSearchInputText) {
      this.inputText(this?.props?.activeCampaignsSearchInputText)
    }
  }

  componentWillUnmount() {
    this.signal.cancel()
    this.start = false
    clearInterval(this.timerId)
    clearInterval(this.firstLoading)
    document.removeEventListener('keydown', this.escFunction, false)

    this?.props?.actionActiveCampaignsFilter()
    this?.props?.actionActiveCampaignsSearchInputText()
    this?.props?.actionActiveCampaignsMessage()

    const isAuth = this.props.dispatch((dispatch, getState) => getState().user.isAuth)
    if (isAuth) {
      this?.props?.actionActiveCampaignsNavigation({
        ...this?.props?.activeCampaignsNavigation,
        maxPages: Math.ceil(
          this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
        ),
        nextPage:
          Math.ceil(this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) > 1
            ? true
            : false,
        currentPage: 1,
        previousPage: false,
      })
    }
  }

  onDismiss = () => {
    this?.props?.actionActiveCampaignsMessage()
  }

  errorMessage = (message) => {
    if (this?.start) {
      this?.props?.actionActiveCampaignsMessage(message)
    }
  }

  getCampaigns = () => {
    getCampaigns(this?.props?.baseUrl, this.signal.token)
      .then((res) => {
        if (this.start) {
          const data = res?.data.map((item) => {
            return {
              ...item,
              name: campaignName(item?.name),
            }
          })

          this?.props?.actionActiveCampaigns(data)

          // если пользователь быстро ввел данные в поиск кампании при повторном монтировании компонента,
          // когда данные в redux в activeCampaigns уже были ранее загружены
          if (this?.props?.activeCampaignsFilter) {
            this?.props?.actionActiveCampaignsNavigation({
              ...this?.props?.activeCampaignsNavigation,
              maxPages: Math.ceil(
                this?.props?.activeCampaignsFilter?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
              ),
              nextPage:
                Math.ceil(
                  this?.props?.activeCampaignsFilter?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
                ) > 1
                  ? true
                  : false,
            })
          } else {
            this?.props?.actionActiveCampaignsNavigation({
              ...this?.props?.activeCampaignsNavigation,
              maxPages: Math.ceil(data?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage),
              nextPage:
                Math.ceil(data?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) > 1 ? true : false,
            })
          }

          clearInterval(this.firstLoading)
          this.updatePage()
        }
      })
      .catch((e) => {
        if (this.start) {
          this.setState({
            errorMessage: e?.errorMessage,
          })
        }
      })
  }

  updatePage = () => {
    this.timerId = setInterval(() => {
      // если сервер был не доступен, очистился redux, идем по этой цепочке кода
      if (!this.props.activeCampaigns) {
        getCampaigns(this?.props?.baseUrl, this.signal.token)
          .then((res) => {
            if (this.start) {
              const data = res?.data.map((item) => {
                return { ...item, name: campaignName(item?.name) }
              })

              this?.props?.actionActiveCampaigns(data)

              this?.props?.actionActiveCampaignsNavigation({
                ...this?.props?.activeCampaignsNavigation,
                maxPages: Math.ceil(data?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage),
                nextPage:
                  Math.ceil(data?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) > 1 ? true : false,
              })
            }
          })
          .catch((e) => {
            if (this.start) {
              this.setState({
                errorMessage: e?.errorMessage,
              })
            }
          })
        return
      }

      // если сервер доступен, идти по этой цепочке кода
      getCampaigns(this?.props?.baseUrl, this.signal.token)
        .then((res) => {
          if (this.start) {
            const data = res?.data.map((item) => {
              return {
                ...item,
                name: campaignName(item?.name),
              }
            })
            this?.props?.actionActiveCampaigns(data)
          }
        })
        .catch((e) => {
          if (this.start) {
            this?.props?.actionActiveCampaigns()
            this?.props?.actionActiveCampaignsFilter()
            this?.props?.actionActiveCampaignsNavigation({
              ...this?.props?.activeCampaignsNavigation,
              maxPages: Math.ceil(
                this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
              ),
              nextPage:
                Math.ceil(this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) >
                1
                  ? true
                  : false,
              currentPage: 1,
              previousPage: false,
            })
            this?.props?.actionActiveCampaignsSearchInputText()
            this?.props?.actionActiveCampaignsMessage()

            this.setState({
              errorMessage: e?.errorMessage,
            })
          }
        })
    }, 2000)
  }

  renderCampaigns = (campaigns) => {
    // вычисляем индекс конечного item (при первой загрузке = 3)
    const lastPageIndex =
      this?.props?.activeCampaignsNavigation?.currentPage * this?.props?.activeCampaignsNavigation?.itemsPerPage

    // вычисляем индекс начального item (при первой загрузке = 0)
    const firstIndex = lastPageIndex - this?.props?.activeCampaignsNavigation?.itemsPerPage

    // забирем нужный перечень items (от и до, при первой загрузке 0, 1, 2)
    const currentItems = campaigns.slice(firstIndex, lastPageIndex)

    return currentItems.map((item, index) => {
      return (
        <div key={item.id} className="campaign-card-block">
          <CardBlock
            key={index}
            data={item}
            baseUrl={this?.props?.baseUrl}
            activeModal={(enable, id, action) => this.activeModal(enable, id, action)}
          />
        </div>
      )
    })
  }

  renderSearch = () => {
    return (
      <div className="input-group mb-3">
        <input
          placeholder="Поиск кампании"
          type="text"
          className="form-control rounded-0 input-search-campaigns"
          value={this?.props?.activeCampaignsSearchInputText}
          onChange={(e) => {
            this?.props?.actionActiveCampaignsSearchInputText(e?.target?.value)
          }}
        />
      </div>
    )
  }

  // запускается при обновлении компонента
  inputText = (inputText) => {
    const dataFilter = this.dataFilter(inputText)
    const dataFilterId = this.dataFilterId(inputText)
    const newData = [...new Set([...dataFilter, ...dataFilterId])]

    if (this.start) {
      if (inputText) {
        this?.props?.actionActiveCampaignsFilter(newData)

        this?.props?.actionActiveCampaignsNavigation({
          ...this?.props?.activeCampaignsNavigation,
          maxPages: Math.ceil(newData?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage),
          nextPage:
            Math.ceil(newData?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) > 1 ? true : false,
          currentPage: 1,
          previousPage: false,
        })
      } else {
        this?.props?.actionActiveCampaignsFilter(null)

        this?.props?.actionActiveCampaignsNavigation({
          ...this?.props?.activeCampaignsNavigation,
          maxPages: Math.ceil(
            this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
          ),
          nextPage:
            Math.ceil(this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) > 1
              ? true
              : false,
          currentPage: 1,
          previousPage: false,
        })
      }
    }
  }

  // поиск кампаний по названию
  dataFilter = (inputText) => {
    const dataFilter = this?.props?.activeCampaigns?.filter((item) => {
      return item?.name?.toLowerCase().indexOf(inputText?.toLowerCase()) > -1
    })
    return dataFilter
  }

  // поиск кампаний по ID
  dataFilterId = (inputText) => {
    const dataFilterId = this?.props?.activeCampaigns?.filter((item) => {
      return String(item?.id)?.toLowerCase() === inputText?.toLowerCase()
    })
    return dataFilterId
  }

  clickOnPage = (numPage) => {
    if (this.start) {
      this?.props?.actionActiveCampaignsNavigation({
        ...this?.props?.activeCampaignsNavigation,
        currentPage: numPage,
        previousPage: numPage === 1 ? false : true,
        nextPage: this?.props?.activeCampaignsNavigation?.maxPages === numPage ? false : true,
      })
    }
  }

  activeModal = (modal = true, id, action) => {
    if (action === 'create report') {
      this.setState({
        modal: modal,
        campaignId: id,
        actionFn: () => this.createTaskComponent(this?.props?.baseUrl, this?.state?.campaignId, this.signal.token),
        modalText: {
          1: 'Создание отчета',
          2: 'Подтверждение запуска',
          3: 'Отмена',
          4: 'Подтвердить',
        },
      })
      return
    }

    if (action === 'delete campaign') {
      this.setState({
        modal: modal,
        campaignId: id,
        actionFn: () => this.deleteCampaign(this?.props?.baseUrl, this?.state?.campaignId),
        modalText: {
          1: 'Удаление кампании',
          2: 'Подтверждение удаления',
          3: 'Отмена',
          4: 'Подтвердить',
        },
      })
      return
    }

    this.setState({
      modal: modal,
      campaignId: null,
      actionFn: null,
      modalText: null,
    })
  }

  escFunction = (event) => {
    if (event?.keyCode === 27) {
      if (this.start) {
        this.setState({
          modal: false,
        })
      }
    }
  }

  createTaskComponent = (url, id, token) => {
    createTask(url, id, token)
      .then(() => {
        if (this.start) {
          this?.props?.history?.push({
            pathname: `/campaign/${id}/reports`,
          })
        }
      })
      .catch((e) => {
        if (this.start) {
          this?.errorMessage(e?.errorMessage)
        }
      })
  }

  deleteCampaign = (baseUrl, campaignId) => {
    deleteCampaign(baseUrl, campaignId).then(() => {
      this?.props?.actionActiveCampaigns(this?.props?.activeCampaigns?.filter((item) => item?.id !== campaignId))
      this?.props?.actionActiveCampaignsFilter(null)
      this?.props?.actionActiveCampaignsSearchInputText('')

      this?.props?.actionActiveCampaignsNavigation({
        ...this?.props?.activeCampaignsNavigation,
        maxPages: Math.ceil(
          this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
        ),
        nextPage:
          Math.ceil(this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage) > 1
            ? true
            : false,
        currentPage: 1,
        previousPage: false,
      })
    })
  }

  render() {
    if (this?.props?.activeCampaigns) {
      if (this?.props?.activeCampaigns?.length === 0) {
        return <CampaignSelect baseUrl={this.props.baseUrl} />
      }

      const campaigns = this.renderCampaigns(
        this?.props?.activeCampaignsFilter ? this?.props?.activeCampaignsFilter : this?.props?.activeCampaigns
      )
      return (
        <>
          <div className="content-wrapper-campaigns">
            <Modal
              state={this.state.modal}
              activeModal={this.activeModal}
              text={this.state.modalText}
              action={this.state.actionFn}
            />

            {this?.props?.activeCampaignsMessage ? (
              <AlertMessage message={this?.props?.activeCampaignsMessage} onDismiss={this.onDismiss} variant="danger" />
            ) : (
              <div className="search-campaign-position">
                <div className="search-campaign-btn">
                  <button
                    type="button"
                    className="btn btn-success rounded-0"
                    onClick={() =>
                      this.props.history.push({
                        pathname: '/campaign/price-format',
                      })
                    }
                  >
                    Новая кампания
                  </button>
                </div>

                <div className="search-campaign">{this.renderSearch()}</div>
              </div>
            )}

            {this?.props?.activeCampaigns?.length >= 0 && this?.props?.activeCampaignsFilter?.length === 0 ? (
              <h5 className="campaign-card-not-found">
                Кампаний не найдено,{' '}
                <span
                  type="button"
                  className="campaign-card-block-btn-clear-input-text"
                  onClick={() => {
                    this?.props?.actionActiveCampaignsFilter()
                    this?.props?.actionActiveCampaignsSearchInputText('')
                    this?.props?.actionActiveCampaignsMessage()

                    this?.props?.actionActiveCampaignsNavigation({
                      ...this?.props?.activeCampaignsNavigation,
                      maxPages: Math.ceil(
                        this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
                      ),
                      nextPage:
                        Math.ceil(
                          this?.props?.activeCampaigns?.length / this?.props?.activeCampaignsNavigation?.itemsPerPage
                        ) > 1
                          ? true
                          : false,
                      currentPage: 1,
                      previousPage: false,
                    })
                  }}
                >
                  очистить поиск.
                </span>
              </h5>
            ) : null}

            <div className="campaign-card-all-blocks">{campaigns}</div>

            {campaignNavigation(this?.props?.activeCampaignsNavigation, this.clickOnPage)}
          </div>
        </>
      )
    } else
      return (
        <div className="content-wrapper-message">
          <div className="cardHeader">
            {this.state.errorMessage ? <h3>{this.state.errorMessage}</h3> : <h3>Загрузка кампаний...</h3>}
          </div>
        </div>
      )
  }
}

const mapStateToProps = (store) => {
  return {
    activeCampaigns: store.userCampaigns.activeCampaigns,
    activeCampaignsFilter: store.userCampaigns.activeCampaignsFilter,
    activeCampaignsNavigation: store.userCampaigns.activeCampaignsNavigation,
    activeCampaignsSearchInputText: store.userCampaigns.activeCampaignsSearchInputText,
    activeCampaignsMessage: store.userCampaigns.activeCampaignsMessage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actionActiveCampaigns: (campaigns) => dispatch(actionActiveCampaigns(campaigns)),
    actionActiveCampaignsFilter: (campaigns) => dispatch(actionActiveCampaignsFilter(campaigns)),
    actionActiveCampaignsNavigation: (navigation) => dispatch(actionActiveCampaignsNavigation(navigation)),
    actionActiveCampaignsSearchInputText: (inputText) => dispatch(actionActiveCampaignsSearchInputText(inputText)),
    actionActiveCampaignsMessage: (message) => dispatch(actionActiveCampaignsMessage(message)),
    dispatch,
  }
}

const Campaigns = connect(mapStateToProps, mapDispatchToProps)(withRouter(CampaignsComponent))
export { Campaigns }
