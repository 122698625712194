import React from 'react';
import ReactDOM from 'react-dom'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { App } from './App'

import { store } from './reducers' // связываем react c redux
import { Provider } from 'react-redux' // для доступа к хранилищу

const firebaseConfig = {
  apiKey: "AIzaSyB5LEY--JfbOzgXcTXT1YIt1ZJ5uNow5NY",
  authDomain: "app-price-react.firebaseapp.com",
  projectId: "app-price-react",
  storageBucket: "app-price-react.appspot.com",
  messagingSenderId: "488587711094",
  appId: "1:488587711094:web:addb809988f64dfaf48557",
  measurementId: "G-N5V954TP4G"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

ReactDOM.render(

    <Provider store={ store }>
    {/* делает хранилище Redux доступным для всех
    вложенных компонентов, которые были обернуты
    в функцию connect (). */}

      <App />

    </Provider>, document.getElementById('root')
)