import React from 'react'
import axios from 'axios'

import { reloadPage } from '../../plugins/reload-page'

const deleteCampaign = async (baseUrl, campaignId) => {
  const url = `${baseUrl}/api/campaign/${campaignId}`

  return await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage?.getItem('access')}`,
      },
    })
    .then((res) => {
      if (res?.status === 200) {
        return res
      } else throw new Error()
    })
    .catch((e) => {
      if (e?.response?.status === 401) {
        reloadPage(e)
        return
      }

      const errorMessage = <>Ошибка сервера, попробуйте повторно...</>
      return { errorMessage: errorMessage }
    })
}

export { deleteCampaign }
