import axios from 'axios'

const resetPassword = (baseUrl, formData, signalToken) => {
  const resetPassword = new Promise((resolve, reject) => {
    const url = `${baseUrl}/api/user/users/reset_password/`

    axios
      .post(url, formData, { cancelToken: signalToken })
      .then((res) => {
        if (res.status === 204) {
          resolve(res)
          return
        }
        throw new Error('Status code not 204')
      })
      .catch((e) => {
        if (e?.response?.status === 400 && e?.response?.data) {
          if (!Array.isArray(e?.response?.data)) {
            reject({ errorMessage: e?.response?.data.email[0] })
            return
          } else {
            reject({ errorMessage: e?.response?.data[0] })
            return
          }
        }

        const errorMessage = 'Ошибка сервера, попробуйте повторить действие.'
        reject({ errorMessage: errorMessage })
      })
  })

  return resetPassword
}

export { resetPassword }
