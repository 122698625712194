import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { actionActiveCampaigns } from '../../../reducers/campaigns-reducer'
import { patchCampaignSettings } from '../../../services/patch-campaign-settings'
import { campaignName } from '../../../plugins/campaign-name'

const ComponentCardFooter = (props) => {
  const dispatch = useDispatch()

  const activeCampaigns = useSelector((state) => {
    return state.userCampaigns.activeCampaigns
  })

  const patchSettings = () => {
    const checkSpaces = (str) => str.trim() === ''

    if (props.campaignSettings?.name?.length === 0 || checkSpaces(props.campaignSettings?.name)) {
      props.setErrorMessage('Название кампании не может быть пустым.')
      return
    }

    const data = {}
    data.name = props.campaignSettings?.name
    data.search_with_filters = props.campaignSettings?.search_with_filters
    data.price_min = props.campaignSettings?.price_min
    data.auto_start = props.autoStart

    patchCampaignSettings(props.baseUrl, props.campaignSettings?.id, data, props.signalToken)
      .then(() => {
        if (!props.loading) {
          if (activeCampaigns != null) {
            let newActiveCampaigns = JSON.parse(JSON.stringify(activeCampaigns))
            newActiveCampaigns.forEach((item) => {
              if (item.id === props.campaignSettings?.id) {
                item.name = campaignName(props.campaignSettings?.name)

                item.auto_start = {
                  ...item.auto_start,
                  ...data.auto_start,
                }
              }
            })
            dispatch(actionActiveCampaigns(newActiveCampaigns))
          }

          props.history.push({
            pathname: `/campaign/${props.campaignSettings?.id}/settings/setup-completed`,
          })
        }
      })
      .catch((e) => {
        if (!props.loading) {
          props.setErrorMessage(e?.errorMessage)
        }
      })
  }

  return (
    <div className="bg-info cardFooter rounded-0">
      <button className="btn btn-light" variant="info" type="submit" onClick={() => patchSettings()} value="Сохранить">
        Сохранить
      </button>
    </div>
  )
}

const CardFooter = withRouter(ComponentCardFooter)
export { CardFooter }
