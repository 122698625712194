import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'

import { getPlans } from '../../services/getPlans/getPlans'
import { AlertMessage } from '../../plugins/alert-message'

const ComponentPayment = ({baseUrl, history}) => {

    const cancelRequest = useRef(null)
    const loading = useRef(true)
    const [plans, setPlans] = useState()
    const [minСostCashlessPayment, setMinСostCashlessPayment] = useState()
    const [errorMessage, setErrorMessage] = useState(null)

    const redux = useSelector(
        (state) => {
            return {
                user_id: state?.user?.currentUser?.id,
            }
        }
    )

    useEffect(() => {
        if (cancelRequest.current == null) {
            cancelRequest.current = axios.CancelToken.source()
        }

        getPlans(baseUrl, cancelRequest.current?.token)
        .then((res) => {
            if (loading.current) {

                const minСostCashless = res?.data?.results?.filter((item) => {
                    return item?.id === 5
                })

                setMinСostCashlessPayment(minСostCashless[0]?.cost * 3)
                setPlans(res?.data?.results)
            }
        })
        .catch((e) => {
            if (loading.current) {
                setErrorMessage(e.errorMessage)
            }
        })

        return( () => {
            cancelRequest.current.cancel()
            loading.current = false
        })

    }, [baseUrl])

    const dataPlans = () => {
        const data = plans.map((item) => {
            return (
                <div key={item?.id} className="col">
                    <div className="card h-100 rounded-0">
                        <div className="card-body text-center">
                            <h5 className="card-title fs-3">{item?.name}</h5>

                            {item?.description && <p className="card-text">{item?.description}</p>}

                            <p className="card-text fs-5">{new Intl.NumberFormat('ru-RU').format(item?.cost) + " ₽"}</p>

                            <button type="button" className="btn btn-link btn-none-shadow" onClick={() => {
                                history.push({
                                    pathname: '/payment/method',
                                    state: {
                                        user_id: redux?.user_id,
                                        plan_id: item?.id,
                                        targets: item?.name,
                                        sum: item?.cost,
                                        minСostCashlessPayment: minСostCashlessPayment
                                    }
                                })
                            }}>Купить</button>

                        </div>
                    </div>
                </div>
            )
        })
        return data
    }

    const onDismiss = () => {
        setErrorMessage(null)
    }

    const getUnitsPlanOfOneDay = () => {
        const units = plans?.filter((item) => {
            return item?.id === 2
        })        

        return new Intl.NumberFormat('ru-RU').format(units[0]?.units)
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="cardHeader">Оплата подписки</div>
                {plans && <div className="mb-3">Выберите необходимое количество дней:</div>}

                <AlertMessage message={ errorMessage } onDismiss={ onDismiss } variant="danger" />
            
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    { plans && dataPlans() }
                </div>

                { plans &&
                    <div className="card mt-3 mb-4 rounded-0">
                        <div className="card-body">
                            <p>Суточный лимит проверок товаров: { getUnitsPlanOfOneDay() }</p>

                            <p>Обновление лимита ежедневно в 00:00 по МСК.</p>
                        </div>
                    </div>
                }

            </div>
        </>
    )
}

const Payment = withRouter(ComponentPayment)
export { Payment }