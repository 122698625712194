import React from 'react'

class Headers extends React.Component {
  constructor() {
    super()

    this.state = {
      priceHeader: 'Игнорировать',
      priceHeaders: {
        0: 'Игнорировать',
        1: 'Название',
        2: 'Цена',
        3: 'Идентификатор',
        4: 'ID карточки / URL',
        5: 'Другие данные',
      },
    }
  }

  priceHeader = (text, id) => {
    const arr = this.state.priceHeaders

    for (let key in arr) {
      if (arr[key] === text) {
        this.setState({
          priceHeader: text,
        })

        let x = {}
        x.idColumn = key
        x.idHeader = id

        this.props.state(x)
      }
    }
  }

  render() {
    return (
      <div className="dropdown">
        <button
          className="headers-css btn btn-info dropdown-toggle rounded-0"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {this.state.priceHeader}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button
              id={this.props.index}
              className="dropdown-item"
              onClick={() => this.priceHeader(this.state.priceHeaders['0'], this.props.index)}
            >
              {this.state.priceHeaders['0']}
            </button>
          </li>
          <li>
            <button
              id={this.props.index}
              className="dropdown-item"
              onClick={() => this.priceHeader(this.state.priceHeaders['3'], this.props.index)}
            >
              {this.state.priceHeaders['3']}
            </button>
          </li>
          <li>
            <button
              id={this.props.index}
              className="dropdown-item"
              onClick={() => this.priceHeader(this.state.priceHeaders['1'], this.props.index)}
            >
              {this.state.priceHeaders['1']}
            </button>
          </li>
          <li>
            <button
              id={this.props.index}
              className="dropdown-item"
              onClick={() => this.priceHeader(this.state.priceHeaders['2'], this.props.index)}
            >
              {this.state.priceHeaders['2']}
            </button>
          </li>
          <li>
            <button
              id={this.props.index}
              className="dropdown-item"
              onClick={() => this.priceHeader(this.state.priceHeaders['4'], this.props.index)}
            >
              {this.state.priceHeaders['4']}
            </button>
          </li>
          <li>
            <button
              id={this.props.index}
              className="dropdown-item"
              onClick={() => this.priceHeader(this.state.priceHeaders['5'], this.props.index)}
            >
              {this.state.priceHeaders['5']}
            </button>
          </li>
        </ul>
      </div>
    )
  }
}

export { Headers }
