import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import { reloadPage } from '../../plugins/reload-page'

const getCampaignSetting = (baseUrl, id, signalToken) => {
  const getCampaignSetting = new Promise(function (resolve, reject) {
    const url = `${baseUrl}/api/campaign/${id}`

    axios
      .get(url, {
        cancelToken: signalToken,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('access')}`,
          'X-User': localStorage.getItem('selectedUserId') || null,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res)
        } else throw new Error()
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          reloadPage(e)
          return
        }

        if (e?.response?.status === 403) {
          const errorMessage = (
            <>
              Кампания не существует, перейти в <Link to="/campaigns">мои кампании.</Link>
            </>
          )
          reject({ errorMessage: errorMessage })
          return
        }

        const errorMessage = (
          <>
            Ошибка сервера, перейти в <Link to="/campaigns">мои кампании.</Link>
          </>
        )
        reject({ errorMessage: errorMessage })
      })
  })
  return getCampaignSetting
}

export { getCampaignSetting }
