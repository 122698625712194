import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actionActiveCampaigns } from '../../../reducers/campaigns-reducer'
import { patchCampaignSettings } from '../../../services/patch-campaign-settings'
import { campaignName } from '../../../plugins/campaign-name'
import { checkAutoStartIsEnabled } from '../../../plugins/check-auto-start-is-enabled'

import { Times } from './Times'

const ComponentCardBody = (props) => {
  const dispatch = useDispatch()

  const activeCampaigns = useSelector((state) => {
    return state.userCampaigns.activeCampaigns
  })

  const activeTabBlock = (block) => {
    if (!block) {
      return 'card-block-off'
    } else return ''
  }

  const regionsNameFormat = () => {
    let data
    props.campaignSettings?.region_names?.forEach((item, index, arr) => {
      if (arr?.length === 1) {
        return (data = item)
      }

      if (data || data) {
        data = data + ', ' + item
      } else {
        data = item
      }
    })
    return data
  }

  const changeCampaignName = (name) => {
    if (!props.loading) {
      props.setCampaignSettings((prevState) => {
        return {
          ...prevState,
          name: name,
        }
      })
    }
  }

  const patchSettings = () => {
    const checkSpaces = (str) => str.trim() === ''

    if (props.campaignSettings?.name?.length === 0 || checkSpaces(props.campaignSettings?.name)) {
      props.setErrorMessage('Название кампании не может быть пустым.')
      return
    }

    const data = {}
    data.name = props.campaignSettings?.name
    data.search_with_filters = props.campaignSettings?.search_with_filters
    data.price_min = props.campaignSettings?.price_min
    data.auto_start = props.autoStart

    patchCampaignSettings(props.baseUrl, props.campaignSettings?.id, data, props.signalToken)
      .then(() => {
        if (!props.loading) {
          if (activeCampaigns != null) {
            let newActiveCampaigns = JSON.parse(JSON.stringify(activeCampaigns))
            newActiveCampaigns.forEach((item) => {
              if (item.id === props.campaignSettings?.id) {
                item.name = campaignName(props.campaignSettings?.name)

                item.auto_start = {
                  ...item.auto_start,
                  ...data.auto_start,
                }
              }
            })
            dispatch(actionActiveCampaigns(newActiveCampaigns))
          }

          props.history.push({
            pathname: `/campaign/${props.campaignSettings?.id}/region-select`,
          })
        }
      })
      .catch((e) => {
        if (!props.loading) {
          props.setErrorMessage(e?.errorMessage)
        }
      })
  }

  const renderAutostartDays = () => {
    const checkboxDay = (day) => {
      return props.setAutoStart((prevState) => {
        return {
          ...prevState,
          [day]: {
            ...prevState[day],
            is_enabled: !prevState[day]['is_enabled'],
            times: prevState[day].times.length === 0 ? ['00:00'] : [...prevState[day].times],
          },
        }
      })
    }

    const nameOfDay = (index) => {
      if (index === 0) return 'ПН'
      if (index === 1) return 'ВТ'
      if (index === 2) return 'СР'
      if (index === 3) return 'ЧТ'
      if (index === 4) return 'ПТ'
      if (index === 5) return 'СБ'
      if (index === 6) return 'ВС'
    }

    const autoStart = Object.keys(props.autoStart).map((day, index) => {
      return (
        <div key={day} className="campaign-settings-autostart-time-block">
          <div className="campaign-settings-autostart-days">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={() => checkboxDay(day)}
                checked={props.autoStart[day]['is_enabled']}
              />
              <label className="form-check-label">{nameOfDay(index)}</label>
            </div>
          </div>
          <div className="campaign-settings-autostart-time">
            <Times day={day} autoStart={props.autoStart} setAutoStart={props.setAutoStart} />
          </div>
        </div>
      )
    })

    return autoStart
  }

  return (
    <>
      <div className={`card-body campaign-settings-card-block ${activeTabBlock(props.tabs.main)}`}>
        <div className="campaign-settings-card-block-settings">
          <div className="campaign-settings-card-block-name">Изменить название кампании:</div>
          <div className="campaign-settings-card-block-other-row campaign-settings-card-block-input-name-block">
            <input
              className="form-control form-control-sm campaign-settings-card-block-input-name"
              type="text"
              value={campaignName(props.campaignSettings?.name)}
              onChange={(e) => changeCampaignName(e?.target?.value)}
            />
          </div>
        </div>

        <div className="">
          <div className="campaign-settings-card-block-name">Настройки парсинга:</div>
          <div className="campaign-settings-card-block-other-row campaign-settings-card-block-text-settings">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={() => {
                  props.setCampaignSettings((prevState) => {
                    return {
                      ...prevState,
                      search_with_filters: !prevState?.search_with_filters,
                    }
                  })
                }}
                checked={props.campaignSettings?.search_with_filters}
              />
              <label className="form-check-label">Поиск карточек с фильтрами</label>
            </div>
            <div className="campaign-settings-card-block-other-row campaign-settings-card-block-prompt">
              например с цветом товара или модификацией товара
            </div>
          </div>

          <div className="campaign-settings-card-block-other-row campaign-settings-card-block-text-settings">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={() => {
                  props.setCampaignSettings((prevState) => {
                    return {
                      ...prevState,
                      price_min: !prevState?.price_min,
                    }
                  })
                }}
                checked={props.campaignSettings?.price_min}
              />
              <label className="form-check-label">Стоимость товара со скидкой</label>
            </div>
            <div className="campaign-settings-card-block-other-row campaign-settings-card-block-prompt">
              выводить конечную стоимость на товар, с учетом всех скидок
            </div>
          </div>
        </div>
      </div>

      <div className={`card-body campaign-settings-card-block ${activeTabBlock(props.tabs.cities)}`}>
        <div className="campaign-settings-card-block-name">Выбранные города:</div>
        <div className="campaign-settings-card-block-other-row campaign-settings-card-block-list-regions">
          {regionsNameFormat()}
        </div>
        <div className="campaign-settings-card-block-other-row">
          <span type="button" className="campaign-settings-card-block-set-regions-btn" onClick={() => patchSettings()}>
            Изменить города
          </span>
        </div>
      </div>

      <div className={`card-body campaign-settings-card-block ${activeTabBlock(props.tabs.autoStart)}`}>
        <div className="campaign-settings-autostart-text-1">Выбрать дни:</div>

        {checkAutoStartIsEnabled(props.autoStart, 'times') ? (
          <div className="campaign-settings-autostart-text-2">Выбрать время:</div>
        ) : null}

        <div className="campaign-settings-autostart">{renderAutostartDays()}</div>
      </div>
    </>
  )
}

const CardBody = withRouter(ComponentCardBody)
export { CardBody }
