const autoStartDaysToRus = (day) => {
  if (day === 'mo') return 'ПН'
  if (day === 'tu') return 'ВТ'
  if (day === 'we') return 'СР'
  if (day === 'th') return 'ЧТ'
  if (day === 'fr') return 'ПТ'
  if (day === 'sa') return 'СБ'
  if (day === 'su') return 'ВС'
}

export { autoStartDaysToRus }
