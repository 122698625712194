const campaignName = (campaignName) => {
    const regexp = (/^campaign_[0-9]+$/gm).test(campaignName)
    
    if (regexp) {
        const splitCampaignName = campaignName.split('_')
        const numCampaign = splitCampaignName[1]
        return 'Кампания ' + numCampaign
    } else {
        return campaignName
    }
}

export { campaignName }