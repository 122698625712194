import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { AlertMessage } from '../../plugins/alert-message'
import { postMessage } from '../../services/verboxChat/post-message'
import { getMessageTg } from '../../services/get-message-tg'

const ComponentCashlessPayment = ({history, baseUrl}) => {

    const cancelRequest = useRef(null)
    const didmount = useRef(true)

    const [successSendMessage, setSuccessSendMessage] = useState(false)
    const [sendMessage, setSendMessage] = useState(false)
    const [file, setFile] = useState()
    const [errorMessage, setErrorMessage] = useState(null)

    const redux = useSelector(
        (state) => {
            return {
                email: state?.user?.currentUser?.email
            }
        }
    )

    useEffect(() => {
        if (cancelRequest.current == null) {
            cancelRequest.current = axios.CancelToken.source()
        }

        return( () => {
            cancelRequest.current.cancel()
            didmount.current = false
        })

    }, [])

    const minСostCashlessPayment = (
        history?.location?.state?.minСostCashlessPayment
        ? 'счет будет подготовлен на ' + new Intl.NumberFormat('ru-RU').format(history?.location?.state?.minСostCashlessPayment) + 'р'
        : 'стоимость уточняется у менеджера'
    )

    const handleFile = (e) => {
        const file = e?.target?.files[0]
        setFile(file)
        setErrorMessage(null)
    }

    const handleSubmitFile = () => {
        if (!file) {
            setErrorMessage('Необходимо загрузить файл с реквизитами.')
            return
        }

        setSendMessage(true)

        let supportOnlineTalkID = document?.cookie?.match(/supportOnlineTalkID=(.*?);/)

        const formData = new FormData()
        formData.append('file', file)
        formData.append('text', 'Прошу подготовить счет на 3 мес, реквизиты доступны по ссылке ')
        formData.append('id', supportOnlineTalkID && supportOnlineTalkID[1])

        postMessage(baseUrl, formData, cancelRequest.current?.token)
        .then((res) => {
            if (didmount.current) {
                setSendMessage(false)
                setSuccessSendMessage(true)

                let link = document.createElement('a')
                link.id = 'clickVerbox'
                link.href = '#Verbox/openMenu'
                document.body.appendChild(link)
                link.click()
                document.getElementById('clickVerbox').remove()
            }
        })
        .catch((e) => {
            if (didmount.current) {
                setSendMessage(false)
                setErrorMessage(e?.errorMessage)

                getMessageTg(
                    'Ошибка отправки запроса на счет, ответ сервера: '+e?.serverMessage+'. Email клиента: '+redux?.email+'',
                    cancelRequest.current?.token
                )
                .catch((e) => {
                    console.log('Ошибка отправки запроса в телеграм: '+e?.errorMessage+'')
                })
            }
        })
    }

    const onDismiss = () => {
        setErrorMessage(null)
    }

    if (sendMessage) {
        return (
            <div className="content-wrapper-message">
                <div className="cardHeader">
                    <h3>Отправляем запрос...</h3>
                </div>
            </div>
        )
    }

    if (successSendMessage) {
        return (
            <div className="content-wrapper-message">
                <div className="cardHeader">
                    <h3>Запрос успешно отправлен!</h3>
                    <h6 className="mt-3">По вопросам выставления счета, можно написать в чат или на <a href="mailto:sale@pricehelper.ru">sale@pricehelper.ru.</a></h6>
                </div>
            </div>
        )
    }

    return (
        <div className="content-wrapper">
            <div className="cardHeader">Оплата подписки, запрос счета</div>
            <AlertMessage message={ errorMessage } onDismiss={ onDismiss } variant="danger" />
            <div className="mb-3">1. Безналичный расчет доступен при оплате от 3 мес, {minСostCashlessPayment}.</div>

            <div>
                <div className="mb-3">2. Загрузите файл с реквизитами:</div>

                <div>
                    <input
                        type="file"
                        style={{'fontSize': '12px'}}
                        onChange={ handleFile }
                        id={'my-input'}
                    />
                </div>

                <div>
                    <button
                        className="btn btn-link btn-cashless-payment"
                        variant="info"
                        type="submit"
                        onClick={ handleSubmitFile }
                        value="Отправить">Отправить
                    </button>
                </div>
            </div>
        </div>
    )
}

const CashlessPayment = withRouter(ComponentCashlessPayment)
export { CashlessPayment }