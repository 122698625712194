import axios from 'axios'

const newPassword = (baseUrl, formData, signalToken) => {
  const newPassword = new Promise((resolve, reject) => {
    const url = `${baseUrl}/api/user/users/reset_password_confirm/`

    axios
      .post(url, formData, { cancelToken: signalToken })
      .then((res) => {
        if (res.status === 204) {
          resolve(res)
          return
        }
        throw new Error('Status code not 204')
      })
      .catch((e) => {
        if (e?.response?.status === 400 && e?.response?.data?.token) {
          reject({ errorMessage: e?.response?.data?.token })
          return
        }

        const errorMessage = 'Ошибка сервера, попробуйте повторить действие.'
        reject({ errorMessage: errorMessage })
      })
  })

  return newPassword
}

export { newPassword }
