import React from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { serverTime } from '../../../plugins/server-time'

import { actionSelectedUser } from '../../../reducers/user-reducer'
import { actionAdminUsersSearchInputText } from '../../../reducers/admin-reducer'
import { actionActiveCampaignsClear } from '../../../reducers/campaigns-reducer'

const ComponentRenderUsers = ({ users, history }) => {
  const dispatch = useDispatch()

  if (!users) {
    return null
  }

  const rows = users?.map((item) => {
    return (
      <tr key={item?.id}>
        <td className="users-table-td" style={{ textAlign: 'center' }}>
          <button
            className="btn btn-warning users-btn-watch"
            onClick={() => {
              dispatch(actionSelectedUser({ id: String(item?.id), email: String(item?.email) }))
              dispatch(actionAdminUsersSearchInputText(''))
              dispatch(actionActiveCampaignsClear())

              localStorage.setItem('selectedUserEmail', item?.email)
              localStorage.setItem('selectedUserId', item?.id)

              history.push({
                pathname: '/campaigns',
              })
            }}
          >
            <div className="bi bi-eye users-watch-svg"></div>
          </button>
        </td>
        <td className="users-table-td">{item?.email}</td>
        <td className="users-table-td">{new Intl.NumberFormat('ru-RU').format(item?.units)}</td>
        <td className="users-table-td">{item?.plan_name ? item?.plan_name : '--'}</td>
        <td className="users-table-td">{item?.plan_end_date ? serverTime(item?.plan_end_date) : '--'}</td>
        <td className="users-table-td">{item?.id}</td>
      </tr>
    )
  })
  return rows
}

const RenderUsers = withRouter(ComponentRenderUsers)
export { RenderUsers }
